const tagName = "about-franklin-friends-component";

const css = /*css*/ `
  :host {
    display: block;
    width: 70%;
    margin: 0 auto;
  }

  .franklin-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 40px 0;
    gap: 40px;
  }

  .franklin-text {
    flex: 1;
    max-width: 50%;
  }

  .franklin-text h2 {
    font-size: clamp(28px, 6.18vw, 45px);
    margin-bottom: 10px;
  }

  .franklin-text p,
  .franklin-text ol {
    font-size: 18px;
    line-height: 1.5;
  }

  .franklin-text ol {
    padding-left: 20px;
  }

  .franklin-text ol li {
    margin-bottom: 10px;
  }

  .franklin-image {
    flex: 1;
    max-width: 50%;
  }

  .franklin-image img {
    max-width: 100%;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    .franklin-container {
      flex-direction: column;
      text-align: left;
    }

    .franklin-text,
    .franklin-image {
      max-width: 100%;
    }
  }
  .light-blue-text {
    color: var(--blue-brand);
    font-size: clamp(28px, 6.18vw, 45px);
}

@media (max-width: 460px) {
  .franklin-text h2 {
    font-size: 40px;
  }
  .light-blue-text {
    font-size: 40px;
  }
}
`;

const renderInnerHTML = () => {
    return /*html*/ `
      <div class="franklin-container">
        <div class="franklin-text">
          <h2>Was ist <br><span class="light-blue-text">Franklin Friends?</span></h2>
          <p>
          <strong>Franklin Friends</strong> ist das exklusive Empfehlungsprogramm des Franklin Institute.
            Du kennst jemanden, der von einer Weiterbildung profitieren könnte? Dann empfehle
            uns weiter und erhalte dafür einen exklusiven Empfehlungsbonus!
          </p>
  
          <h3>Und so funktioniert’s – In 3 einfachen Schritten</h3>
          <ol>
            <li><strong>Empfehlen:</strong> Nutze deinen <strong>individuellen Empfehlungslink</strong> oder unser <strong>Kontaktformular.</strong></li>
            <li><strong>Erfolgreicher Kursstart:</strong> Sobald deine empfohlene Person startet, zählt die Empfehlung. Für deine  <strong>erste erfolgreiche Empfehlung erhältst du 400€</strong>.</li>
            <li><strong>Empfehlungsbonus erhalten:</strong> Dein Bonus wird in <strong>monatlichen Raten von 100€</strong> ausgezahlt.</li>
          </ol>
        </div>
        <div class="franklin-image">
          <img src="/assets/images/businesspeople-meeting-office 1.png" alt="Franklin Friends" />
        </div>
      </div>
      <style>${css}</style>
    `;
  };  

class FranklinFriendsComponent extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.shadowRoot.innerHTML = renderInnerHTML();
  }
}

customElements.define(tagName, FranklinFriendsComponent);

export default `<${tagName}></${tagName}>`;
