function checkIsFriendCode(code){
    var validChars = "ABCDEFGHKLMNPQRSTUVWXYZ";
    if(typeof code != 'string'){
        return false
    }
    if(code.length != 6){
        return false
    }
    for(let i=0; i<code.length; i++){
        if(!validChars.includes(code[i].toUpperCase())){
            return false
        }
    }
    return true
}

function store(){
    try{
        var qString = location.href.split('?')[1]
        if(!qString){return}
        var res = {};
        var params = qString.split('&');
        params.forEach(param=>{
            if(param.includes('utm_source')){res.utm_source = param.split('=')[1]}
            if(param.includes('utm_medium')){res.utm_medium = param.split('=')[1]}
            if(param.includes('utm_campaign')){res.utm_campaign = param.split('=')[1]}
            if(param.includes('utm_content')){res.utm_content = param.split('=')[1]}
            if(param.includes('fc')){
                var value = param.split('=')[1];
                if(checkIsFriendCode(value)){
                    res.fc = value
                }
            }
        });
        if(Object.keys(res).length == 0){return}
        var resString = btoa(btoa(JSON.stringify(res)));
        sessionStorage.setItem('utm_carry', resString);
    }catch(e){
        console.log("err @ utm-carry: " + e);
    }
}

function load(prefix=""){
    var res = "";
    var defaultUTMSource = "website-cta";
    try{
        var utmString = atob(atob(sessionStorage.getItem('utm_carry')));
        var utmObject = JSON.parse(utmString);
        if(!utmObject.utm_source){
            utmObject.utm_source = defaultUTMSource;
        }else{
            utmObject.utm_source = `${defaultUTMSource}__${utmObject.utm_source}`;
        }
        Object.keys(utmObject).forEach(key=>{
            if(key != "fc"){
                res += `${key}=${utmObject[key]}&`;
            }
        });
        res = prefix + res.slice(0, -1);
        if(utmObject.fc){
            res += `#friend_code=${utmObject.fc}`;
        }
        return res
    }catch(err){
        res = prefix + "utm_source=" + defaultUTMSource;
        return res;
    }
}

export default {store, load}