import { getAllBlogPosts, getBlogPostById } from '/services/blog.service.js';
import '/components/blog-carousel-component.js';
import { slugify } from '/services/util.js';
import UTM from '/services/utm-carry.js';
import '/components/blog-banner.js';

// If you use util for setting page title/description, make sure it's imported or available globally.
// import util from '/services/util.js';

const tagName = "blog-individual-page";

var css = /*css*/`
  ${tagName} {
    display: block;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
  }

  ${tagName} .blog-title {
    font-size: 2.5em;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
    padding-bottom: 10px;
  }

  ${tagName} .blog-meta {
    font-size: 1.1em;
    color: #777;
    text-align: center;
    margin-bottom: 20px;
  }

  ${tagName} .blog-summary {
    font-size: 1.1em;
    color: #444;
    margin-bottom: 20px;
    text-align: center;
    font-style: italic;
  }

  ${tagName} .blog-summary a,
  ${tagName} .section-summary a {
    color: var(--blue-brand);
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
    text-underline-offset: 2px; 
  } 

  ${tagName} .thumbnail {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  ${tagName} .section {
    margin-bottom: 2em;
  }

  ${tagName} .section-header {
    font-size: 1.8em;
    font-weight: bold;
    color: #333;
  }

  ${tagName} .section-summary {
    margin-bottom: 1em;
    color: #444;
    font-size: 1.05em;
  }

  ${tagName} .section-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    margin-top: 1em;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }

  /* Skeleton (loading) styles */
  ${tagName} .skeleton-title {
    height: 2.5em;
    background: #ccc;
    border-radius: 0.5em;
    margin-bottom: 14px;
    margin-top: 35px;
    animation: shimmer 1.5s infinite;
  }

  ${tagName} .skeleton-title-bar {
    height: 2.5em;
    width: 90%;
    background: #ddd;
    border-radius: 0.5em;
    margin: 0 auto 20px;
    animation: shimmer 1.5s infinite;
    margin-bottom: 20px;
  }

  ${tagName} .skeleton-meta {
    height: 1.5em;
    width: 35%;
    background: #ddd;
    border-radius: 0.5em;
    margin: 0 auto 20px;
    animation: shimmer 1.5s infinite;
  }

  ${tagName} .skeleton-summary {
    height: 1.5em;
    width: 100%;
    background: #ddd;
    border-radius: 0.5em;
    margin: 0 auto 10px;
    animation: shimmer 1.5s infinite;
  }

  ${tagName} .skeleton-thumbnail {
    width: 100%;
    height: 400px;
    background: #eee;
    border-radius: 0.4em;
    margin-bottom: 20px;
    animation: shimmer 1.5s infinite;
    margin-top: 20px;
  }

  ${tagName} .skeleton-paragraph {
    height: 1.2em;
    background: #ddd;
    border-radius: 0.5em;
    margin-bottom: 10px;
    animation: shimmer 1.5s infinite;
  }

  @keyframes shimmer {
    0% { background-position: -200px 0; }
    100% { background-position: 200px 0; }
  }
`;

function getTypeformURL() {
  const baseUrl = 'https://franklin-institute.typeform.com/website-leads';
  let utmString = UTM.load('?'); 
  return baseUrl + utmString;
}

function renderBlogHTML(blogData) {
  const safeTitle = blogData.title?.trim() || "Untitled Post";
  const safeDate = blogData.date_published
      ? new Date(blogData.date_published).toLocaleDateString()
      : "No publish date";
  const safeReadTime = blogData.read_time && blogData.read_time > 0
      ? `${blogData.read_time} min Lesedauer`
      : "N/A";
  const safeSummary = blogData.summary?.trim() || "No summary available";
  const safeThumbnail = blogData.thumbnail?.trim()
      ? blogData.thumbnail
      : "https://via.placeholder.com/600x400";

  return /*html*/`
    <h1 class="blog-title">${safeTitle}</h1>
    <div class="blog-meta">
      Veröffentlicht am ${safeDate} | ${safeReadTime}
    </div>
    <h2 class="blog-summary">${safeSummary}</h2>
    <img src="${safeThumbnail}" alt="${safeTitle}" class="thumbnail" />

    <!-- Render the "sections" array if it exists -->
    ${renderSections(blogData.sections || [])}

    <style>${css}</style>
  `;
}

function renderSections(sections = []) {
  return sections
    .map((section) => {
      const header = section.header ? section.header.trim() : "No Header";

      // Render paragraphs (each as a <p>)
      let paragraphsHTML = "";
      if (Array.isArray(section.paragraphs)) {
        paragraphsHTML = section.paragraphs
          .map(p => `<p>${p.trim()}</p>`)
          .join("");
      } else if (typeof section.paragraphs === "string") {
        paragraphsHTML = `<p>${section.paragraphs.trim()}</p>`;
      }

      // Render additional paragraphs (can be an array or a string)
      let additionalParagraphHTML = "";
      if (section.additional_paragraph) {
        additionalParagraphHTML = `<p>${section.additional_paragraph.trim()}</p>`;
      } else if (section.additional_paragraphs) {
        if (Array.isArray(section.additional_paragraphs)) {
          additionalParagraphHTML = section.additional_paragraphs
            .map(p => `<p>${p.trim()}</p>`)
            .join("");
        } else if (typeof section.additional_paragraphs === "string") {
          additionalParagraphHTML = `<p>${section.additional_paragraphs.trim()}</p>`;
        }
      }

      // Render section image if provided
      const sectionImage = section.image ? section.image.trim() : "";
      const imageHTML = sectionImage
        ? `<img src="${sectionImage}" class="section-image" alt="Section image" />`
        : "";

      // Render list items
      const listItems = Array.isArray(section.list) ? section.list : [];
      const listHTML = listItems.length
        ? `<ul>${listItems.map((item) => {
            // If the list item is an object with a "text" property
            if (typeof item === "object" && item.text) {
              let subpointsHTML = "";
              if (Array.isArray(item.subpoints) && item.subpoints.length > 0) {
                subpointsHTML = `<ul>${item.subpoints.map(sub => `<li>${sub}</li>`).join("")}</ul>`;
              }
              return `<li>${item.text}${subpointsHTML}</li>`;
            } else {
              // Otherwise, render the item directly (as a string)
              return `<li>${item}</li>`;
            }
          }).join('')}</ul>`
        : "";

      return /*html*/ `
        <div class="section">
          <div class="section-header">${header}</div>
          <div class="section-summary">
            ${paragraphsHTML}
            ${listHTML}
            ${additionalParagraphHTML}
          </div>
          ${imageHTML}
        </div>
      `;
    })
    .join("");
}

class BlogIndividualPage extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    // 1) Show a skeleton while we fetch data
    this.innerHTML = `
      <div class="skeleton-title"></div>
      <div class="skeleton-title-bar"></div>
      <div class="skeleton-meta"></div>
      ${Array(2).fill(0).map(() => `<div class="skeleton-summary"></div>`).join('')}
      <div class="skeleton-thumbnail"></div>
      ${Array(6).fill(0).map(() => `<div class="skeleton-paragraph"></div>`).join('')}
      <style>${css}</style>
    `;

    // 2) Extract the slug from the URL path
    const pathParts = window.location.pathname.split("/");
    const rawSlug = pathParts[2] || "";
    const slugFromURL = decodeURIComponent(rawSlug);

    // 3) Get all blogs to find the matching ID by slug
    const allBlogs = await getAllBlogPosts();
    if (!Array.isArray(allBlogs)) {
      this.innerHTML = `<p>Could not load blog data.</p>`;
      return;
    }

    // 4) Match the blog by slug
    const matchedBlog = allBlogs.find((b) => slugify(b.title) === slugFromURL);
    if (!matchedBlog) {
      this.innerHTML = `<h1>404 - Blog Not Found</h1>`;
      return;
    }

    // 5) Fetch the full blog details
    const blogData = await getBlogPostById(matchedBlog.id);
    if (!blogData) {
      this.innerHTML = `<p>Could not fetch the full blog details.</p>`;
      return;
    }

    // Remove the skeleton
    this.innerHTML = "";

    // Dynamically set the page title & description
    const dynamicTitle = `${blogData.title} | Franklin Institute of Applied Sciences`;
    const dynamicDescription = blogData.summary?.trim();
    util.setPageTitle(dynamicTitle);
    util.setPageDescription(dynamicDescription);

    // Store UTM data
    this.storeUTMData(blogData);

    // Render the main blog content into a container
    const blogContainer = document.createElement('div');
    blogContainer.innerHTML = renderBlogHTML(blogData);
    this.appendChild(blogContainer);

    // Insert a spacer between blog content and banner
    const spacer = document.createElement('div');
    spacer.style.height = '20px';
    this.appendChild(spacer);

    // Instead of the old hero-button, add the new blog-banner.
    if (blogData.category) {
      const finalCTA = getTypeformURL();
      const bannerElement = document.createElement('blog-banner');
      bannerElement.setAttribute('category', blogData.category);
      bannerElement.setAttribute('cta-url', finalCTA);
      // Pass blog-id and blog-title so the banner can correctly build its link.
      bannerElement.setAttribute('blog-id', blogData.id);
      bannerElement.setAttribute('blog-title', blogData.title);
      this.appendChild(bannerElement);
    }

    // Append the carousel at the bottom (excluding the current blog)
    if (blogData.id) {
      const carouselElement = document.createElement('blog-carousel-component');
      carouselElement.setAttribute('exclude-id', blogData.id);
      this.appendChild(carouselElement);
    }

    // Update main menu
    var updateMainMenuEvent = new CustomEvent("update-main-menu");
    var mainMenu = document.querySelector('main-menu');
    if (mainMenu) {
      mainMenu.dispatchEvent(updateMainMenuEvent);
    }
  }

  storeUTMData(blogData) {
    const utmBlogData = {
      blogData: {
        id: blogData.id,
        slug: slugify(blogData.title)
      }
    };
    UTM.store(utmBlogData);

    var resetAppConfigEvent = new CustomEvent("reset-app-config");
    window.dispatchEvent(resetAppConfigEvent);
  }
}

customElements.define(tagName, BlogIndividualPage);

export default `<${tagName}></${tagName}>`;
