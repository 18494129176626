import DoubleCTABox from '../components/double-cta-box.js';
import '/config.js';
import aboutFranklinFriendsComponent from "../components/about-franklin-friends-component.js";
import franklinRecommendationComponent from "../components/franklin-recommendation-component.js";
import HeroButton from "../components/hero-button.js";

const tagName = "franklin-friends";

var pageTitle = "Franklin Friends | Franklin Institute of Applied Sciences";
var pageDescription = "Franklin Friends ist das exklusive Empfehlungsprogramm des Franklin Institute. Du kennst jemanden, der von einer Weiterbildung profitieren könnte? Dann empfehle uns weiter und verdiene dabei bares Geld!";

var css = /*css*/`
.title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 10px;
    margin-bottom: -10px;
  }
`;

function template(){
    return /*html*/`

    <about-franklin-friends-component></about-franklin-friends-component>
    <div style="display: flex; justify-content: center; margin-top: -25px; margin-bottom: 30px;">
        <a cta-prevent-inner-hover target="_blank" href="${config.ctaURLS.franklinfriends}" class="menu-desktop is-cta">
            ${HeroButton(`<span>Jetzt starten!</span>`)}
        </a>
    </div>    
    <franklin-recommendation-component></franklin-recommendation-component>

    <div class="double-cta-cont" style="padding: 3em 1rem; background: linear-gradient(to bottom, white 0%, white 50%, var(--gray-brand) 50%, var(--gray-brand) 100%);">
    ${DoubleCTABox({
        texts: [
            "Interressiert?",
            "Dein Empfehlungslink wartet!",
            "Registriere dich jetzt, erhalte deinen individuellen Empfehlungslink und verdiene ganz einfach mit deinen Weiterempfehlungen!"
        ],
        ctas: [
            {label: "Jetzt starten!", href: config.ctaURLS.franklinfriends, internal: false, newTab: true},
        ]
    })}
</div>

    <style>${css}</style>
        
    `;
}

// Define the main page component
class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template();
    }
}

// Register the main page component
customElements.define(tagName, Page);

// Export the main component if needed
export default `<${tagName}></${tagName}>`;