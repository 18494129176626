import '/config.js';

const tagName = "kontakt-boxes-component";

var contactCss = /*css*/ `
.contact-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 60px;
    padding: 40px 350px;
    background-color: #ffffff;
    grid-auto-rows: 1fr;
}

.contact-box {
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 230px;
    cursor: pointer;
    justify-self: center;
}

.contact-box:hover {
    transform: translateY(-5px);
}

.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.contact-icon {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
}

.icon-phone {
    margin-top: -35px;
    margin-bottom: 0px;
}

/* Titles */
.contact-box h3 {
    font-size: 20px;
    font-family: 'inter-bold', sans-serif;
    margin-bottom: 5px;
    color: #333;
}

/* Secondary text */
.contact-box p {
    font-size: 12px;
    font-family: 'inter-medium', sans-serif;
    color: #555;
    margin: 5px 0;
}

/* Links */
.contact-box a {
    color: var(--blue-brand, #0056b3);
    font-size: 16px;
    text-decoration: none;
    font-family: 'inter-medium', sans-serif;
    margin-top: 5px;
}

.contact-box a:hover {
    border-bottom: 2px solid var(--blue-brand, #007BFF);
}

/* Button Styling */
.contact-box .button {
    width: fit-content;
    padding: 1em 2em;
    color: white;
    background: var(--blue-brand, #007BFF);
    border-radius: 0.3em;
    cursor: pointer;
    box-shadow: 0 0.6em 0.8em rgba(0, 0, 0, 0.2);
    transition: background-color 200ms ease-in-out, transform 200ms;
    text-decoration: none;
    font-size: 16px;
    font-family: 'inter-medium', sans-serif;
    display: inline-block;
    border: none;
    margin-top: 10px;
}

.contact-box .button span {
    font-size: 1.1em;
    border-bottom: 2px solid white;
}

/* Hover Effect */
.contact-box .button:hover {
    background: var(--gray-brand);
    transform: translateY(-2px);
}

@media (max-width: 953px) {
    .contact-section {
        grid-template-columns: 1fr;
        padding: 20px 10px;
    }

    .contact-box {
        max-width: 100%;
    }
}

@media (max-width: 1192px) {
    .contact-section {
        grid-template-columns: 1fr;
        padding: 40px;
    }

    .contact-box {
        max-width: 100%;
    }
}

@media (max-width: 1605px) {
    .contact-section {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        gap: 30px;
        padding: 40px 200px;
    }

    .contact-box:nth-child(2) {
        grid-column: 1 / 3;
        grid-row: 2;
        justify-self: center;
    }
}

@media (max-width: 835px) {
    .contact-section {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        width: 80%;
        length: auto;
        justify-self: center;
        padding: 75px;
    }

    .contact-box:nth-child(2) {
        grid-column: auto;
        grid-row: auto;
    }

    .contact-box {
        width: 80%;
        justify-self: center;
    }
}

@media (max-width: 835px) {
    .contact-section {
        padding: 60px;
    }
}

@media (max-width: 460px) {
    .contact-section {
        padding: 40px;
    }
}
`;


const renderButtonContHTML = (options) => {
    if (options.ctas.length === 1) {
        return /*html*/ `<a class="button" ${options.ctas[0].internal ? "internal" : ""} ${options.ctas[0].newTab ? 'target="_blank"' : ""} href="${options.ctas[0].href}"><span>${options.ctas[0].label}</span></a>`;
    }
};


var renderInnerHTML = () => /*html*/ `
<section class="contact-section">
    <!-- Call Us -->
    <div class="contact-box">
        <div class="contact-content">
            <img src="/assets/images/Phone.png" class="contact-icon icon-phone" alt="Phone Icon">
            <h3>Ruf uns an</h3>
            <p></p>
            <a href="tel:+498962826149">+49 89 62826149</a>
        </div>
    </div>

    <!-- Chat with Us -->
    <div class="contact-box">
        <div class="contact-content">
            <img src="/assets/images/Chat.png" class="contact-icon" alt="Chat Icon">
            <h3>Schreib uns</h3>
            <p>Wir sind von Montag 9:00 CET bis Freitag 19:00 CET erreichbar.</p>
            <a href="https://wa.me/+498962826149" target="_blank">WhatsApp: +49 89 62826149</a>
            <a href="mailto:Beratung@franklin-institute.de">Beratung@franklin-institute.de</a>
        </div>
    </div>

    <!-- Sign Up -->
    <div class="contact-box">
        <div class="contact-content">
            <img src="/assets/images/Anmelden.png" class="contact-icon" alt="Anmelden Icon">
            <h3>Interesse?</h3>
            <p>Füllen Sie unser Formular aus und wir melden uns</p>
            <div class="button-cont">
                ${renderButtonContHTML({
                    ctas: [{ label: "Jetzt anmelden!", href: config.ctaURLS.main, internal: false, newTab: true }]
                })}
            </div>
        </div>
    </div>
</section>
`;

class KontaktBoxes extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const shadow = this.attachShadow({ mode: "open" });

        const container = document.createElement("div");
        container.innerHTML = renderInnerHTML();

        const style = document.createElement("style");
        style.textContent = contactCss;

        shadow.appendChild(style);
        shadow.appendChild(container);
    }
}

customElements.define(tagName, KontaktBoxes);

export default KontaktBoxes;
