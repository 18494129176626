const tagName = "white-cta-box";

var css = /*css*/`
    ${tagName} {
        display: block;
        background: var(--gray-brand-gradient); /* White background */
        padding: 1em;
        border-radius: 1em; /* Rounded corners */
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        max-width: 600px;
        margin: 5em auto;
        margin-bottom: 70px;
        box-shadow: 0 0.7em 1em rgba(0, 0, 0, 0.2);
        transition: 200ms;
        max-width: 600px;
    }
    ${tagName} .flex-col-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 12px;
    }
    ${tagName} .text-1 {
        font-family: 'inter-bold', 'sans-serif';
        font-size: 36px;
        color: white;
    }
    ${tagName} .text-2 {
        font-family: 'inter-bold', 'sans-serif';
        font-size: 28px;
        color: white;
    }
    ${tagName} .text-3 {
        font-family: 'inter-medium', 'sans-serif';
        font-size: 18px;
        color: white;
    }
    ${tagName} .button-cont {
        display: flex;
        gap: 1em;
        margin-top: 0.5em;
        justify-content: center;
        flex-wrap: wrap;
    }
    ${tagName} .button {
        width: fit-content;
        padding: 1em 2em;
        color: black;
        background: var(--light-blue-gradient);
        border-radius: 0.3em;
        cursor: pointer;
        box-shadow: 0 0.6em 0.8em rgba(0, 0, 0, 0.2);
        transition: 200ms;
    }
    body.has-hover ${tagName} .button:hover {
        transform: translateY(-2px);
    }
    ${tagName} .button span {
        font-size: 1.1em;
        font-weight: 800px;
    }

    @media (max-width: 634px) {

        ${tagName} {
            max-width: 500px;
        }

    }

    @media (max-width: 400px) {

        ${tagName} {
            max-width: 330px;
            margin-top: 40px;
        }

        ${tagName} .text-1 {
            font-size: 34px;

        }

    }
`;

const renderButtonContHTML = (options)=>{
    if(options.ctas.length == 1){
        return /*html*/`<a class="button" ${options.ctas[0].internal ? "internal" : ""} ${options.ctas[0].newTab ? 'target="_blank"' : ""} href="${options.ctas[0].href}"><span>${options.ctas[0].label}</span></a>`;
    }else{
        return /*html*/`
            <a class="button" ${options.ctas[0].internal ? "internal" : ""} ${options.ctas[0].newTab ? 'target="_blank"' : ""} href="${options.ctas[0].href}"><span>${options.ctas[0].label}</span></a>
            <a class="button" ${options.ctas[1].internal ? "internal" : ""} ${options.ctas[1].newTab ? 'target="_blank"' : ""} href="${options.ctas[1].href}"><span>${options.ctas[1].label}</span></a>
        `;
    }
};

const renderInnerHTML = (options)=>{
    return /*html*/`
        <div class="flex-col-center">
            <div class="text-1">
                ${options.texts[0]}
            </div>
            <div class="text-2">
                ${options.texts[1]}
            </div>
            <div class="text-3">
                ${options.texts[2]}
            </div>
            <div class="button-cont">
                ${renderButtonContHTML(options)}
            </div>
        </div>
        <style>${css}</style>
    `;
};

class WhiteCtaBox extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        this.options = JSON.parse(atob(this.getAttribute('element-options')));
        this.removeAttribute('element-options');
        this.innerHTML = renderInnerHTML(this.options);
    }
}

customElements.define(tagName, WhiteCtaBox);

function render(options){
    return `<${tagName} element-options="${btoa(JSON.stringify(options))}"></${tagName}>`;
}

export default render;
