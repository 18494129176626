const tagName = "franklin-recommendation-component";

const css = /*css*/ `
  :host {
    display: block;
    width: 80%;
    margin: 0 auto;
  }

  .recommendation-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    background: #fff;
    padding: 60px;
    padding-top: 20px;
  }

  .recommendation-box {
    flex: 1;
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding-top: 0;
  }

  .recommendation-box h3 {
    font-size: 24px;
  }

  .recommendation-step {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;
  }

  .step-number {
    width: 60px;
    height: 60px;
    background: var(--blue-brand);
    color: white;
    font-size: 28px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .step-content {
    flex: 1;
  }

  .step-content h3 {
    font-size: 20px;
    color: var(--blue-brand);
    margin: 0 0 5px;
  }

  .step-content p {
    font-size: 16px;
    color: #444;
    margin: 0;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    color: var(--blue-brand);
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .recommendation-container {
      flex-direction: column;
    }
  }

  @media (max-width: 460px) {

    :host {
      width: 90%;
    }

    .recommendation-container {
      flex-direction: column;
      padding: 10px;
    }
  
    .recommendation-box h3 {
      text-align: center;
    }
  
    .recommendation-step {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .step-number {
      align-self: flex-start;
    }
  
    .step-content p {
      margin-top: 10px;
    }  

    .step-content h3 {
      text-align: left;
    }
  }
`;

const renderInnerHTML = () => {
  return /*html*/ `
    <h2 class="title">Deine Möglichkeiten zur Empfehlung</h2>
    <div class="recommendation-container">
      <div class="recommendation-box">
        <h3>Option 1: Tracking-Link</h3>
        <div class="recommendation-step">
          <div class="step-number">1</div>
          <div class="step-content">
            <h3>Link Erhalten</h3>
            <p>Wir schicken dir einen personalisierten Link zu.</p>
          </div>
        </div>

        <div class="recommendation-step">
          <div class="step-number">2</div>
          <div class="step-content">
            <h3>Teilen & Werben</h3>
            <p>Teile den Link mit potenziellen Interessenten.</p>
          </div>
        </div>

        <div class="recommendation-step">
          <div class="step-number">3</div>
          <div class="step-content">
            <h3>Bonus Erhalten</h3>
            <p>Sobald sich jemand über diesen Link anmeldet, wird der Kontakt dir zugeordnet und du erhältst deinen Bonus!</p>
          </div>
        </div>
      </div>

      <div class="recommendation-box">
        <h3>Option 2: Persönliche Empfehlung</h3>
        <div class="recommendation-step">
          <div class="step-number">1</div>
          <div class="step-content">
            <h3>Kontaktdaten Übermitteln</h3>
            <p>Übermittle die Kontaktdaten der Person, die du weiterempfehlen möchtest, direkt über ein spezielles Formular übermitteln.</p>
          </div>
        </div>

        <div class="recommendation-step">
          <div class="step-number">2</div>
          <div class="step-content">
            <h3>Absprache Treffen</h3>
            <p>Bitte informiere die Person, damit sie von unserem Anruf nicht überrascht wird.</p>
          </div>
        </div>

        <div class="recommendation-step">
          <div class="step-number">3</div>
          <div class="step-content">
            <h3>Bonus Erhalten</h3>
            <p>Sobald sich die Person anmeldet, wird der Kontakt dir zugeordnet und du erhältst erhältst deinen Bonus!</p>
          </div>
        </div>
      </div>
    </div>
    <style>${css}</style>
  `;
};

class FranklinRecommendationComponent extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.shadowRoot.innerHTML = renderInnerHTML();
  }
}

customElements.define(tagName, FranklinRecommendationComponent);

export default `<${tagName}></${tagName}>`;
