// File: /components/scarcity-progress-bar.js
import '/config.js';  // Ensures window.config is available

const tagName = 'scarcity-progress-bar';

const css = /*css*/ `
  ${tagName} {
    display: block;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  /* Outer container with gradient border */
  .progress-container {
    width: 91%;
    height: 28px; 
    background: var(--light-blue-gradient, linear-gradient(90deg, #0a84ff, #3aa0ff));
    border-radius: 9999px; 
    padding: 2px;         
    box-sizing: border-box;
    position: relative;
  }

  /* White interior area */
  .inner {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 9999px;
    position: relative;
    overflow: hidden;
  }

  /* Gradient fill with white border */
  .progress-fill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0%;
    border: 2px solid #fff;
    border-radius: 9999px;
    background: var(--light-blue-gradient, linear-gradient(90deg, #0a84ff, #3aa0ff));
    transition: width 0.4s ease;
    z-index: 1;
  }

  /* Text displayed underneath the progress bar */
  .progress-text {
    width: 91%;
    text-align: center;
    font-family: 'inter-bold', sans-serif;
    font-size: 12px;
    color: #333;
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    .progress-container,
    .progress-text {
      width: 100%;
    }
  }
`;

function renderInnerHTML(progressValue) {
  return /*html*/ `
    <div class="progress-container">
      <div class="inner">
        <div class="progress-fill" style="width: ${progressValue}%;"></div>
      </div>
    </div>
    <div class="progress-text">${progressValue}% der Plätze sind vergeben, melden Sie sich jetzt!</div>
    <style>${css}</style>
  `;
}

class ScarcityProgressBar extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // Get relevant attributes or default them
    this.courseSlug = this.getAttribute('courseSlug') || 'pm';
    this.thresholdDays = parseInt(this.getAttribute('threshold') || '14', 10);

    // Calculate the final progress and set the HTML
    const progressValue = this.calculateProgress();
    this.innerHTML = renderInnerHTML(progressValue);
  }

  /**
   * Calculates a number between 70% and 95% based on how close today's date
   * is to the course's nextStartDate in window.config.courseData.
   */
  calculateProgress() {
    const courseData = window.config.courseData[this.courseSlug];
    if (!courseData) {
      console.warn(`No course data found for slug: ${this.courseSlug}`);
      return 72;
    }

    const nextStartDate = new Date(courseData.nextStartDate);
    const today = new Date();

    // If start date is today or in the past, clamp to 89%.
    if (today >= nextStartDate) {
      return 89;
    }

    // Compute days remaining
    const msPerDay = 1000 * 60 * 60 * 24;
    const daysRemaining = Math.ceil((nextStartDate - today) / msPerDay);

    // If more than thresholdDays remain, show 72%.
    if (daysRemaining > this.thresholdDays) {
      return 72;
    }

    // Otherwise, linearly interpolate from 72% -> 89% over the last thresholdDays
    const daysPassedWithinThreshold = this.thresholdDays - daysRemaining;
    const fraction = daysPassedWithinThreshold / this.thresholdDays;
    const progress = 72 + fraction * 25; // 72 -> 89 range

    return Math.min(89, Math.round(progress));
  }
}

customElements.define(tagName, ScarcityProgressBar);

export default ScarcityProgressBar;
