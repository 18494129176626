// blog-card-component.js

const tagName = "blog-card-component";

const css = /*css*/ `
  :host {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 500px;
    border-radius: 0.8rem;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    font-size: min(16px, 3vw);
    overflow: hidden;
    background: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }

  :host(:hover) {
    transform: scale(1.01);
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .thumbnail {
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: center;
    background: #ccc;
  }

  .text-cont {
    flex: 1; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;
    gap: 0.8em;
  }

  .text-content-top {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .text-title {
    font-size: 1.3em;
    line-height: 1.2em;
    font-family: 'inter-bold';
    color: #333;
  }

  .text-summary {
    font-size: 1em;
    font-family: 'inter-medium';
    line-height: 1.4em;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .read-time {
    font-size: 0.9em;
    font-family: 'inter-medium';
    color: #777;
  }

  /* Button styles */
  .read-more {
    display: inline-block;
    padding: 0.5em 1em;
    background: var(--blue-brand, #007BFF); /* Fallback to blue if variable not set */
    color: #fff;
    border-radius: 0.5em;
    font-size: 0.9em;
    text-align: center;
    transition: background 0.3s ease;
    margin-top: auto;
  }

  .read-more:hover {
    background: var(--gray-brand, #0056b3); /* Fallback to darker blue */
  }

  @media (max-width: 490px) {
    .text-summary {
      font-size: 1em; /* Adjusted to prevent overflow */
    }

    .text-title {
      font-size: 1.3em;
    }

    .read-time {
      font-size: 1em;
    }

    .read-more {
      font-size: 1em;
    }

    .thumbnail {
      height: 150px;
    }

    :host {
      height: 370px;
      width: 85%;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
`;

const renderInnerHTML = (options) => {
  return /*html*/ `
      <img src="${options.thumbnail}" alt="${options.title}" class="thumbnail" loading="lazy" />
      <div class="text-cont">
        <div class="text-content-top">
          <div class="text-title">${options.title}</div>
          <div class="text-summary">${options.summary}</div>
          <div class="read-time">${options.read_time}</div>
        </div>
        <div class="read-more">Mehr Lesen</div>
      </div>
    <style>${css}</style>
  `;
};

class BlogCard extends HTMLElement {
  constructor() {
    super();
    // Attach Shadow DOM
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    try {
      const options = JSON.parse(decodeURIComponent(this.getAttribute("element-options") || '{}'));
      this.shadowRoot.innerHTML = renderInnerHTML(options);
    } catch (e) {
      console.error('Failed to parse element-options:', e);
      this.shadowRoot.innerHTML = `<div style="padding: 1em;">Invalid blog post data.</div>`;
    }
    this.removeAttribute("element-options");
  }
}

customElements.define(tagName, BlogCard);

function render(options) {
  const e = window.encodeURIComponent;
  return `
    <${tagName} element-options="${e(JSON.stringify(options))}">
    </${tagName}>
  `;
}

export default render;
