import BlogCard from '/components/blog-card-component.js';
import '/config.js';
import '/services/util.js';
import { getAllBlogPosts } from '/services/blog.service.js';
import { slugify } from '/services/util.js';

const tagName = "blog-overview-page";

const pageTitle = "Blog | Franklin Institute of Applied Sciences";
const pageDescription = "Alles rund um Karriere, Weiterbildung und persönliche Entwicklung";

// CSS styles for the component including the pagination bar.
const css = /*css*/`
  ${tagName} .blog-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 30px 20px;
      padding: 20px;
      padding-bottom: 40px;
      max-width: 1000px;
      margin: 0 auto;
  }

  .light-blue-text {
    color: var(--blue-brand);
    font-size: clamp(28px, 6.18vw, 45px);
    text-align: center;
    font-family: 'inter-bold';
    padding-bottom: 10px;
  }
  
  .centered-text {
    font-size: clamp(28px, 6.18vw, 14px);
    padding-bottom: 20px;
    text-align: center;
    font-family: 'inter-bold';
  }
  
  ${tagName} .hero-title {
      text-align: center;
      font-family: 'inter-bold', sans-serif;
      font-size: 2.5rem;
      margin-bottom: 30px;
      color: #333;
  }
  
  /* Skeleton styles */
  ${tagName} .skeleton-card {
      display: flex;
      flex-direction: column;
      width: 320px;
      height: 500px;
      border-radius: 0.8rem;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      overflow: hidden;
      background: #fff;
      animation: shimmer 1.5s infinite;
  }
  ${tagName} .skeleton-thumbnail {
      width: 100%;
      height: 180px;
      background: #ddd;
  }
  ${tagName} .skeleton-text {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding: 0.8em;
  }
  ${tagName} .skeleton-title {
      height: 1.6em;
      background: #ccc;
      border-radius: 0.3em;
      width: 70%;
      margin-top: 0.5em;
  }
  ${tagName} .skeleton-title-bar {
      height: 1.6em;
      background: #ccc;
      border-radius: 0.3em;
      width: 90%;
      margin-bottom: 1em;
  }
  ${tagName} .skeleton-summary {
      height: 1em;
      background: #ccc;
      border-radius: 0.3em;
      width: 100%;
  }
  ${tagName} .skeleton-summary-bar {
      height: 1em;
      background: #ddd;
      border-radius: 0.3em;
      width: 100%;
      margin-bottom: 0.1em;
  }
  ${tagName} .skeleton-read-more {
    display: inline-block;
    padding: 15px;
    background: var(--blue-brand, #007BFF);
    color: #fff;
    border-radius: 0.4em;
    margin-top: 1.5em;
    margin-left: 1em;
    margin-right: 1em;
  }
  
  ${tagName} a {
    display: block;
    text-decoration: none;
  }
  
  ${tagName} a:hover blog-card-component {
    transform: scale(1.01);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 490px) {
    ${tagName} .skeleton-card {
      height: 450px;
    }
    ${tagName} .skeleton-read-more {
      margin-bottom: 0.82em;
      padding: 15px;
      margin-left: 0.8em;
      margin-right: 0.8em;
    }
    ${tagName} .skeleton-summary {
      height: 0.8em;
    }
    ${tagName} .skeleton-summary-bar {
      height: 0.8em;
    }
    ${tagName} .skeleton-title {
      height: 1.4em;
    }
    ${tagName} .skeleton-title-bar {
      height: 1.4em;
    }
    ${tagName} .skeleton-text {
      gap: 0.7em;
    }
  
    .light-blue-text {
      padding-top: 20px;
      font-size: 35px;
    }
    .centered-text {
      font-size: 16px;
    }
  }
  
  @keyframes shimmer {
      0% { background-position: -200px 0; }
      100% { background-position: 200px 0; }
  }
  
  /* Pagination styles */
  ${tagName} .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    gap: 5px;
  }
  ${tagName} .pagination button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background: #fff;
    cursor: pointer;
    font-size: 1rem;
  }
  ${tagName} .pagination button.active {
    background: var(--blue-brand, #007BFF);
    color: #fff;
    border-color: var(--blue-brand, #007BFF);
  }
  ${tagName} .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

/**
 * Generates the HTML for the pagination bar.
 *
 * @param {number} currentPage - The current page number.
 * @param {number} totalPages - The total number of pages.
 * @returns {string} The HTML for the pagination bar.
 */
function generatePaginationHTML(currentPage, totalPages) {
  let pages = '';
  for (let i = 1; i <= totalPages; i++) {
    pages += `<button class="pagination-page ${i === currentPage ? 'active' : ''}" data-page="${i}">${i}</button>`;
  }
  return `
    <div class="pagination">
      <button class="pagination-prev" ${currentPage === 1 ? 'disabled' : ''}>&lt;</button>
      ${pages}
      <button class="pagination-next" ${currentPage === totalPages ? 'disabled' : ''}>&gt;</button>
    </div>
  `;
}

/**
 * Generates the complete HTML for the overview page.
 *
 * @param {Array} blogs - Array of blog post objects (for the current page).
 * @param {number} currentPage - The current page number.
 * @param {number} totalPages - The total number of pages.
 * @returns {string} The full HTML.
 */
function generateHTML(blogs, currentPage = 1, totalPages = 1) {
  if (!blogs || blogs.length === 0) {
    return `
      <div class="wrapper">
        <div class="light-blue-text">Neueste Artikel</div>
        <div class="centered-text">Alles rund um Karriere, Weiterbildung und persönliche Entwicklung</div>
        <p>No blogs found.</p>
        <style>${css}</style>
      </div>
    `;
  }
  
  return `
    <div class="wrapper">
      <div class="light-blue-text">Neueste Artikel</div>
      <div class="centered-text">Alles rund um Karriere, Weiterbildung und persönliche Entwicklung</div>
      <div class="blog-grid">
        ${blogs.map(blog => {
          // Use safe fallbacks for missing data.
          const safeTitle = blog.title?.trim() || "Untitled Post";
          const safeSummary = blog.summary?.trim() || "No summary available";
          const safeReadTime = (blog.read_time && blog.read_time > 0)
              ? `${blog.read_time} min Lesedauer`
              : "N/A";
          const safeThumbnail = blog.thumbnail?.trim()
              ? blog.thumbnail
              : "https://via.placeholder.com/300";
          const slug = slugify(safeTitle);
  
          return `<a internal href="/blog/${slug}">` +
                 BlogCard({
                   title: safeTitle,
                   thumbnail: safeThumbnail,
                   summary: safeSummary,
                   read_time: safeReadTime,
                   link: `/blog/${slug}`
                 }) +
                 `</a>`;
        }).join('')}
      </div>
      ${generatePaginationHTML(currentPage, totalPages)}
    </div>
    <style>${css}</style>
  `;
}

class BlogOverviewPage extends HTMLElement {
  constructor() {
    super();
    this.currentPage = 1;
    this.itemsPerPage = 9; // Show 9 posts per page.
    this.totalCount = 0;   // Total number of posts (set after fetching).
    this.blogData = [];    // Full list of posts.
  }
  
  async connectedCallback() {
    // Set page title and description.
    util.setPageTitle(pageTitle);
    util.setPageDescription(pageDescription);
    
    // Show skeleton view while data is loading.
    this.renderSkeleton();
    
    try {
      // Fetch **all** blog posts by overriding the default limit.
      const blogDataResponse = await getAllBlogPosts({ limit: 1000, skip: 0 });
      
      // If the API returns an object with a "posts" property, use it.
      let posts = [];
      let total = 0;
      if (blogDataResponse && blogDataResponse.posts && Array.isArray(blogDataResponse.posts)) {
        posts = blogDataResponse.posts;
        total = blogDataResponse.total || posts.length;
      } else if (Array.isArray(blogDataResponse)) {
        posts = blogDataResponse;
        total = posts.length;
      }
      
      // Sort posts by date (newest first).
      posts.sort((a, b) => {
        const dateA = new Date(a.date_published);
        const dateB = new Date(b.date_published);
        if (isNaN(dateA)) return 1;
        if (isNaN(dateB)) return -1;
        return dateB - dateA;
      });
      
      this.blogData = posts;
      this.totalCount = total;
      this.currentPage = 1;
      this.renderPage();
      
    } catch (error) {
      console.error("Error fetching or processing blog data:", error);
      this.innerHTML = `
        <div class="wrapper">
          <div class="hero-title">Latest Blogs</div>
          <p>Failed to load blogs. Please try again later.</p>
          <style>${css}</style>
        </div>
      `;
    }
  }
  
  renderSkeleton() {
    this.innerHTML = `
      <div class="wrapper">
        <div class="light-blue-text">Neueste Artikel</div>
        <div class="centered-text">Alles rund um Karriere, Weiterbildung und persönliche Entwicklung</div>
        <div class="blog-grid">
          ${Array(3)
            .fill(0)
            .map(() => `
              <div class="skeleton-card">
                <div class="skeleton-thumbnail"></div>
                <div class="skeleton-text">
                  <div class="skeleton-title"></div>
                  <div class="skeleton-title-bar"></div>
                  <div class="skeleton-summary"></div>
                  <div class="skeleton-summary-bar"></div>
                  <div class="skeleton-summary-bar"></div>
                  <div class="skeleton-summary-bar"></div>
                </div>
                <div class="skeleton-read-more"></div>
              </div>
            `)
            .join('')}
        </div>
        <style>${css}</style>
      </div>
    `;
  }
  
  renderPage() {
    // Calculate the total number of pages.
    const totalPages = Math.ceil(this.totalCount / this.itemsPerPage);
    // Slice the full blog list for the current page.
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const currentBlogs = this.blogData.slice(startIndex, startIndex + this.itemsPerPage);
    
    this.innerHTML = generateHTML(currentBlogs, this.currentPage, totalPages);
    
    // --- Attach event listeners to the pagination buttons ---
    const prevButton = this.querySelector('.pagination-prev');
    const nextButton = this.querySelector('.pagination-next');
    
    if (prevButton) {
      prevButton.addEventListener('click', () => {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.renderPage();
          window.scrollTo(0, 0);
        }
      });
    }
    
    if (nextButton) {
      nextButton.addEventListener('click', () => {
        if (this.currentPage < totalPages) {
          this.currentPage++;
          this.renderPage();
          window.scrollTo(0, 0);
        }
      });
    }
    
    // Attach event listeners for numbered page buttons.
    const pageButtons = this.querySelectorAll('.pagination-page');
    pageButtons.forEach(button => {
      button.addEventListener('click', event => {
        const page = parseInt(event.currentTarget.getAttribute('data-page'));
        if (!isNaN(page) && page !== this.currentPage) {
          this.currentPage = page;
          this.renderPage();
          window.scrollTo(0, 0);
        }
      });
    });
  }
}

customElements.define(tagName, BlogOverviewPage);
export default `<${tagName}></${tagName}>`;
