import FeedbackSection from '/sections/feedback-section.js';
import DetailsZumBildungstraegerSection from '/sections/details-zum-bildungstraeger-section.js';

const tagName = "bildungsgutschein-page";

var pageTitle = "Weiterbildung beim Franklin Institute | Dein Weg zum zukunftssicheren Traumjob";
var pageDescription = "Entdecke spannende Weiterbildungen beim Franklin Institute of Applied Sciences. Wir freuen uns auf den Kontakt mir dir";

var videoURL = "https://fast.wistia.com/embed/medias/cdagv2xm9n";

var css = /*css*/`
    .video-section {
        display: block;
        text-align: center;
        margin-top: 1rem;
        padding: 20px;
    }
    .video-section iframe {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        aspect-ratio: 16 / 9;
        border-radius: 20px;
        overflow: hidden;
        border: none;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    }
    .section-message {
        font-family: 'inter-bold';
        margin-bottom: 1rem;
        margin-top: -30px;
    }
    .light-blue-text {
        color: var(--blue-brand);
        font-size: clamp(28px, 6.18vw, 45px);
    }
    .centered-text {
        font-size: clamp(28px, 6.18vw, 45px);
        padding-bottom: 20px;
    }
    .video-description {
        margin-top: 30px;
        font-family: 'inter';
        font-size: 16px;
        color: #333;
        line-height: 1.5;
        text-align: center;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
    }
    .contact-info {
        margin-top: 0px;
        font-family: 'inter';
        font-size: 16px;
        color: #333;
        line-height: 1.5;
        text-align: center;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        padding: 20px;
        padding-top: 0px;

    }
    .contact-link {
        color: var(--blue-brand);
    }
`;

var template = /*html*/`
    <style>${css}</style>

    <!-- Video Section -->
    <div class="video-section">
        <div class="section-message centered-text">
            <span>Dein Weg zum</span>
            <span class="light-blue-text">Bildungsgutschein! <br></span>
        </div>
        <iframe 
            src="${videoURL}" 
            allow="autoplay; fullscreen" 
            allowfullscreen>
        </iframe>
        <div class="video-description">
            In diesem Video zeigen wir dir, wie du deinen <strong>Bildungsgutschein online auf der Website der Bundesagentur für Arbeit beantragen</strong> kannst. 
            Diesen Bildungsgutschein benötigst du, um an einer zertifizierten Weiterbildung bei uns teilnehmen zu können. 
            Wie das funktioniert, wird <strong>im Video Schritt für Schritt erklärt</strong>.
        </div>
    </div>

    <details-zum-bildungstraeger-section></details-zum-bildungstraeger-section>

    <div class="contact-info">
            Wenn du weitere Fragen hast, kannst du dich jederzeit mit deinem Ansprechpartner in Verbindung setzen. 
            Schreib einfach eine E-Mail an 
            <a href="mailto:Beratung@franklin-institute.de" class="contact-link">Beratung@franklin-institute.de</a> 
            oder ruf uns an unter 
            <a href="tel:+498962826149" class="contact-link">+49 89 62826149</a> 
            oder melde dich per WhatsApp bei deinem Berater.
    </div>
        <div class="feedback-section-wrapper">
            ${FeedbackSection} 
        </div>
`;

class Bildungsgutschein extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = template;
    }
}

customElements.define(tagName, Bildungsgutschein);

export default `<${tagName}></${tagName}>`;
