function collect(){
    var res = {};
    res.url = location.href;
    res.ua = navigator.userAgent;
    res.path = location.pathname;
    res.query = location.search;
    res.referrer = document.referrer;
    res.screen = `${screen.width}x${screen.height}`;
    return res
}

function send(sessionID){
    var data = collect();
    data.sessionID = sessionID;
    var keyNames = {
        "166289319": "sessionID",
        "602384603": "url",
        "1915151226": "ua",
        "2000770061": "path",
        "694532345": "query",
        "1024801784": "referrer",
        "2001789906": "screen"
    };
    var sendData = {};
    Object.keys(keyNames).forEach(fieldID => {
        var key = keyNames[fieldID];
        sendData[fieldID] = data[key];
    });
    var body = "";
    Object.keys(sendData).forEach(fieldID => {
        var str = `entry.${fieldID}=${encodeURIComponent(sendData[fieldID])}&`;
        body += str;
    });
    body = body.slice(0, -1);
    var url = atob("aHR0cHM6Ly9kb2NzLmdvb2dsZS5jb20vZm9ybXMvZC9lLzFGQUlwUUxTZkFrRDBqZGgwalRHMmExRmxIaVo5OFU5SVNaS0twZEhHTGt2QXhvWHM1anQtemNBL2Zvcm1SZXNwb25zZQ==");
    fetch(url, {method: "POST", mode: "no-cors", body, headers: {"content-type": "application/x-www-form-urlencoded"}});
}

export default {
    send
}