// blog-banner.js

const style = /*css*/ `
  :host {
    position: relative; 
    display: block;
    margin: 2rem auto;
    max-width: 800px;
    overflow: visible;
  }

  /* Host pseudo-element for the blue background */
  :host::before {
    content: "";
    position: absolute;
    top: 10px;
    left: -8px;
    right: 0;
    bottom: -8px;
    background-color: var(--blue-brand, #008dd5);
    border-radius: 20px;
    z-index: 0;
  }

  .banner-content {
    position: relative;
    padding: 1.5rem 1.8rem;
    font-family: 'inter-medium', sans-serif;
    color: #333;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.15);
    z-index: 1;
  }

  h2 {
    margin: 0 0 1rem;
    font-family: 'inter-bold', sans-serif;
    font-size: 1.8rem;
    line-height: 1.2;
    color: #1e2e45;
  }

  p {
    margin: 0 0 1.2rem;
    line-height: 1.5;
    font-size: 1rem;
    color: #444;
  }

  .cta-button {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-family: 'inter-bold', sans-serif;
    background-color: var(--blue-brand, #008dd5);
    color: #fff;
    text-decoration: none;
    border: 2px solid transparent;
    border-radius: 30px;
    transition: background 0.3s ease, transform 0.3s ease, color 0.3s ease, border 0.3s ease;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #fff;
    color: var(--blue-brand, #008dd5);
    border: 2px solid var(--blue-brand, #008dd5);
    transform: scale(0.98);
  }

  /* Hide the helper inputs */
  input.blog-id,
  input.blog-title {
    display: none;
  }
`;

const bannerHTML = {
  allgemein: (ctaUrl) => /*html*/ `
    <div class="banner-content">
      <h2>KARRIERE IM IT- UND MANAGEMENTBEREICH</h2>
      <p>
        Starte Deine Zukunft im Produkt- oder Vertriebsmanagement mit unserer praxisnahen Weiterbildung.
        In wenigen Monaten lernst Du, Produkte erfolgreich zu entwickeln oder Vertriebsstrategien gezielt umzusetzen.
        Unsere Kurse sind staatlich gefördert. Lass Dich beraten!
      </p>
      <a class="cta-button" href="${ctaUrl}">Vereinbare noch Heute Dein unverbindliches Erstgespräch</a>
    </div>
  `,
  pm: (ctaUrl) => /*html*/ `
    <div class="banner-content">
      <h2>Produktmanager als Beruf</h2>
      <p>
        Wenn Du eine Karriere im Produktmanagement anstrebst, bieten wir Dir die passenden Weiterbildungskurse,
        um Dich optimal vorzubereiten. In wenigen Monaten lernst Du alle wichtigen Fähigkeiten – praxisnah und
        begleitet von erfahrenen Experten. Unsere Kurse vermitteln Dir das notwendige Know-how, um Produkte
        erfolgreich zu planen, zu entwickeln und am Markt zu positionieren. Die Weiterbildung ist staatlich gefördert.
        Wir beraten Dich gerne!
      </p>
      <a class="cta-button" href="${ctaUrl}">Vereinbare noch Heute Dein unverbindliches Erstgespräch</a>
    </div>
  `,
  sales: (ctaUrl) => /*html*/ `
    <div class="banner-content">
      <h2>Vertriebsmanager als Beruf</h2>
      <p>
        Wenn Du Deine Zukunft im Vertriebsmanagement siehst, unterstützen wir Dich mit einer gezielten Weiterbildung.
        Innerhalb weniger Monate eignest Du Dir praxisnah und unter Anleitung erfahrener Experten alle wesentlichen Kenntnisse
        an, um erfolgreich im Vertrieb zu agieren. Du lernst, wie Du Verkaufsstrategien entwickelst, Kunden gewinnst und Umsätze steigerst.
        Unsere Kurse sind staatlich gefördert. Lass Dich von uns beraten!
      </p>
      <a class="cta-button" href="${ctaUrl}">Vereinbare noch Heute Dein unverbindliches Erstgespräch</a>
    </div>
  `
};

class BlogBanner extends HTMLElement {
  static get observedAttributes() {
    return ['cta-url', 'blog-id', 'blog-title', 'category'];
  }
  
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }
  
  connectedCallback() {
    this.render();
    console.log('BlogBanner connected:', {
      ctaUrl: this.getAttribute('cta-url'),
      blogId: this.getAttribute('blog-id'),
      blogTitle: this.getAttribute('blog-title'),
      category: this.getAttribute('category'),
    });
  }
  
  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.render();
    }
  }
  
  render() {
    const ctaUrl = this.getAttribute('cta-url') || '';
    const blogId = this.getAttribute('blog-id') || '';
    const blogTitle = this.getAttribute('blog-title') || '';
    const categoryValue = this.getAttribute('category') || 'allgemein';
    const templateFn = bannerHTML[categoryValue] || bannerHTML.allgemein;
    
    // Set inner HTML and include hidden inputs to hold the blog data.
    this.shadowRoot.innerHTML = `
      ${templateFn(ctaUrl)}
      <input type="hidden" class="blog-id" value="${blogId}">
      <input type="hidden" class="blog-title" value="${blogTitle}">
      <style>${style}</style>
    `;
    
    // (Re)attach the click handler to the CTA button after rendering.
    const btn = this.shadowRoot.querySelector('.cta-button');
    if (btn) {
      btn.onclick = this.handleCTAClick.bind(this);
    }
  }
  
  handleCTAClick(event) {
    event.preventDefault();
    // Read the blog data from the hidden inputs (ensuring we get the correct values)
    const blogId = this.shadowRoot.querySelector('.blog-id')?.value || '';
    const blogTitle = this.shadowRoot.querySelector('.blog-title')?.value || '';
    let ctaUrl = this.getAttribute('cta-url') || '';
    
    if (ctaUrl && blogId && blogTitle) {
      try {
        const url = new URL(ctaUrl, window.location.origin);
        url.searchParams.set('blog_id', blogId);
        url.searchParams.set('blog_title', blogTitle);
        console.log('CTA clicked for blog:', { blogId, blogTitle, url: url.toString() });
        window.location.href = url.toString();
      } catch (error) {
        console.error('Error constructing URL:', error);
        window.location.href = ctaUrl;
      }
    } else {
      console.warn('Missing blog data. Navigating to base URL:', ctaUrl);
      window.location.href = ctaUrl;
    }
  }
}

customElements.define('blog-banner', BlogBanner);
export default BlogBanner;
