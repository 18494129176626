const tagName = "teacher-section";

const teacherData = {
    js: {
        img: "dozenten-portrait-1(new).jpg",
        name: "Jan-Henrik Stocker",
        title: "Principal Product Manager",
        text: "Motivierter Teamleiter und Produktmanager für digitale Produkte mit einer starken Leidenschaft für Strategie. Über 8 Jahre Erfahrung im Produktmanagement, als Gründer und Angel Investor. Liebt es, tief in die Kernbedürfnisse der Kunden, Unternehmensabläufe und Marktentwicklungen einzutauchen, um daraus eine überzeugende Unternehmensstrategie abzuleiten."
    },
    br: {
        img: "dozenten-portrait-3(new).jpg",
        name: "Beate Radics",
        title: "Lead PM Coach",
        text: "Leidenschaftliche Product Managerin und Product Owner für digitale und IoT-Produkte mit Fokus auf den Kunden in der Zusammenarbeit mit DEV, Design & Analytics. Über 10 Jahre Erfahrung in Product Ownership & Teamleitung. Liebt die Kooperation mit allen Bereichen der Produktentwicklung, um für den User das beste Produkt zu entwickeln."
    },
    md: {
        img: "dozenten-portrait-4(new).jpg",
        name: "Minja Derviskadic",
        title: "Senior Career Coach",
        text: "Über 10 Jahre Führungserfahrung mit bis zu 80 Mitarbeitern als Regionalleiterin und Projektleiterin. Über 4 Jahre Jahre Erfahrung als (agile / Job) Coach und Teamcoach. Leidenschaft für systemisches Coaching, agiles Management und die Digitalisierung von Produkten & Vertriebsprozessen. Zertifizierte Systemische Trainerin & SCRUM Master. Gründerin des Startup MY H&B Clinic GmbH."
    },
    uoc: {
        img: "portrait-ulf.jpg",
        name: "Ulf Oliveros-Calvo",
        title: "IT Sales Coach",
        text: "Product Owner, Scrum Master, Sales Experte & Coach... Über 12 Jahre Sales-, Führungs-, Projekt- und Coachingerfahrung in der IT sowie im Bereich Klimaschutz Leidenschaftlicher Agile Enthusiast, DJ und Podcast Host.Macht seit 30 Jahren Musik, “goes the extra mile. every time”, auch für seine Kursteilnehmer."
    },
    sp: {
        img: "portrait-steven.jpg",
        name: "Steven Parker",
        title: "IT Sales Coach",
        text: "Sales Manager für digitale Produkte, Digital Marketing Manager, SEO BVDW Fachkraft, Vertical Farming Sales Manager, zertifizierter systemischer Coach. Knapp 20 Jahre Erfahrung im B2B Sales und Marketing mit dem Fokus auf ehrlicher und ergebnisorientierter Beratung, mit dem Anspruch seinen Kunden und Kursteilnehmern immer eine helfende Hand zu reichen. Liebt es Menschen bei Ihrer beruflichen und persönlichen Entwicklung zu unterstützen."
    },
    tr: {
        img: "Tobi_Riede.jpg",
        name: "Tobias Riede",
        title: "PM Coach", 
        text: "Erfahrener Teamleiter und Produktmanagement-Coach mit Fokus auf den Aufbau erfolgreicher Produktorganisationen. Spezialisiert auf agile Methoden im Mittelstand und Konzern, um Teams kundenorientiert und effizient zu gestalten. Umfangreiche Erfahrung als Projektleiter für digitale Lösungen im sechsstelligen Bereich, mit der Fähigkeit, Produktstrategien gezielt mit wirtschaftlichen Zielen zu verbinden. Tätigkeit in führenden Digitalagenturen wie TEAM23, flytech, LUMIUM und Agile Heroes sowie als Coach und Dozent am Franklin Institute."
    },
};

const courseTeachers = {
    pm: ["js", "br", "md", "tr"],
    sales: ["js", "md", "sp", "uoc"]
};

var css = /*css*/`
    ${tagName} {
        display: block;
        font-size: 1em;
        padding: 3em 0;
        padding-bottom: 2em;
    }
    ${tagName} .teacher-cont {
        display: flex;
        flex-direction: column;
        gap: 4.3em;
        margin-top: 4.3em;
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 3em;
    }
    ${tagName} .teacher-box {
        display: flex;
        gap: 2.5em;
    }
    ${tagName} .teacher-portrait-box-outer {
        display: block;
        min-width: 216px;
        min-height: 216px;
    }
    ${tagName} .teacher-portrait-box-inner {
        position: relative;
    }
    ${tagName} .teacher-portrait-box-inner div {
        display: block;
        width: 200px;
        aspect-ratio: 1 / 1;
        position: absolute;
        border-radius: 4px;
    }
    ${tagName} .teacher-portrait-box-inner div:nth-child(1) {
        background: var(--blue-brand);
        top: 16px;
        left: 16px;
    }
    ${tagName} .teacher-portrait-box-inner div:nth-child(2) {
        background: var(--gray-brand);
        top: 8px;
        left: 8px;
    }
    ${tagName} .teacher-portrait-box-inner div:nth-child(3) {
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }
    ${tagName} .teacher-text {
        font-family: 'inter-medium';
        text-align: justify;
    }
    ${tagName} .teacher-name {
        margin-bottom: 0.3em !important;
    }
    ${tagName} .teacher-title {
        margin-bottom: 0.5em !important;
    }
    @media(max-width: 739px){
        ${tagName} .teacher-box {
            flex-direction: column;
            gap: 2rem;
            align-items: center;
            max-width: 450px;
            margin: auto;
        }
        ${tagName} .teacher-text-box {
            max-width: 90%;
            margin: auto;
        }
    }
    @media(max-width: 420px){
        ${tagName} .teacher-text {
            text-align: left;
        }
        ${tagName} .teacher-box {
            gap: 1.2rem;
        }
        ${tagName} .teacher-cont {
            gap: 3rem;
        }
    }
    @media(max-width: 339px){
        ${tagName} .teacher-text-box {
            max-width: initial;
            margin: initial;
        }
    }
`;

const renderTeacherElements = function(slug){
    var res = "";
    var teachers = courseTeachers[slug];
    teachers.forEach(teacher=>{
        var teacherHTML = /*html*/`
            <div class="teacher-box">
                <div class="teacher-portrait-box-outer">
                    <div class="teacher-portrait-box-inner">
                        <div></div>
                        <div></div>
                        <div style="background-image: url('/assets/images/${teacherData[teacher].img}');"></div>
                    </div>
                </div>
                <div class="teacher-text-box">
                    <div class="teacher-name section-message page-hero-medium font-scale-down">${teacherData[teacher].name}</div>
                    <div class="teacher-title section-message page-hero-small">${teacherData[teacher].title}</div>
                    <div class="teacher-text">${teacherData[teacher].text}</div>
                </div>
            </div>
        `;
        res += teacherHTML;
    });
    return res;
}

const renderInnerHTML = (options)=>{
    var slug = options.courseSlug;
    return /*html*/`
        <div class="section-message page-hero-medium centered font-scale-down" style="margin-bottom: 0.3em;">Unsere leitenden Dozenten</div>
        <div class="section-message centered" style="font-size: 1.3em;">Lerne das Management unseres Dozententeams kennen</div>
        <div class="teacher-cont">
            ${renderTeacherElements(slug)}
        </div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.style.backgroundImage = `url('${this.options.imageURL}')`;
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render