import HeroButton from '/components/hero-button.js';
import UTM from '/services/utm-carry.js'
import '/services/event-bus.js'
import '/config.js'

const tagName = "main-menu";

var css = /*css*/`
${tagName} .nav-bar-cont {
    width: 100%;
    font-size: 18px;
    position: fixed;
    top: 0;
    background: #ffffff;
    z-index: 5;
    transition: 200ms;
    box-shadow: none; /* No shadow initially */
}

/* Shadow class that will be added on scroll */
${tagName} .nav-bar-cont.has-shadow {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Shadow added on scroll */
}

    ${tagName} .menu-label {
        font-family: 'inter-medium', 'sans-serif';
        font-size: 14px; 
        text-align: center;
        color: black; 
        margin-right: -1px;
        margin-top: -8px;
    }

    body.has-hover ${tagName} .menu-link.menu-desktop.kurs-dropdown {
        margin-right: 15px; 
    }
    
    ${tagName} .announcement-cont {
        display: none;
        background: var(--light-blue-gradient);
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
        box-sizing: border-box;
        font-family: 'inter-medium';
        font-size: 1.1em;
    }
    @media (max-width: 439px) {
        ${tagName} .announcement-cont {
            font-size: 1em;
        }
    }
    @media (max-width: 369px) {
        ${tagName} .announcement-cont {
            font-size: 0.9em;
        }
    }
    ${tagName} .announcement-cont:before {
        content: '';
        display: flex;
        width: 40px;
        height: 25px;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('/assets/images/calendar-gray.svg');
    }
    ${tagName} .announcement-cont[data-visible="true"] {
        display: flex;
    }
    ${tagName} .main-cont {
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;
        padding: 0px 18px;
        max-width: 1920px;
        margin: 0 auto;
    }
    ${tagName} .button-cont {
        display: flex;
        gap: 40px;
        align-items: center;
        position: relative;
    }
    ${tagName} .main-logo {
        height: 60px;
        aspect-ratio: 255/79;
        background-image: url('/assets/images/fias-logo-new.svg');
    }
    body.has-hover ${tagName} .menu-link {
        transition: 200ms;
        font-family: 'inter-medium', 'sans-serif';
        font-size: 1em;
        cursor: pointer;
        position: relative;
    }
    body.has-hover ${tagName} .menu-link:hover {
        color: var(--blue-brand);
    }
    /* Down arrow only for "Unsere Kurse" */
    body.has-hover ${tagName} .menu-link.menu-desktop.kurs-dropdown::after {
        content: '▼';
        font-size: 0.7em;
        position: absolute;
        right: -17px;
        top: 11px;
        transform: translateY(-50%);
        color: var(--gray-brand);
        transition: 200ms;
    }
    body.has-hover ${tagName} .menu-link.menu-desktop.kurs-dropdown:hover::after {
        color: var(--blue-brand);
    }

    /* Dropdown menu styling with smoother corners */
    ${tagName} .dropdown-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #ffffff; /* Background white */
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        z-index: 10;
        flex-direction: column;
        padding: 10px 0;
        min-width: 200px;
        border-radius: 8px; /* Added border-radius for smoother corners */
    }
    ${tagName} .dropdown-menu a {
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        font-size: 0.9em;
        color: black;
        transition: color 200ms;
    }
    ${tagName} .dropdown-menu a:hover {
        background-color: var(--light-blue-gradient);
        color: var(--blue-brand); /* Change text color on hover */
    }
    
    ${tagName} .menu-link.kurs-dropdown:hover .dropdown-menu {
        display: flex;
    }
    
    /* Mobile-specific styles */
    @media (min-width: 1150px) {
        ${tagName} .menu-mobile {
            display: none !important;
        }
    }
    @media (max-width: 1149px) {
        ${tagName} .menu-desktop {
            display: none !important;
        }
    }
    ${tagName} .menu-mobile-btn {
        position: relative;
        display: flex;
        width: 60px;
        height: 50px;
        flex-direction: column;
        cursor: pointer;
        transition: 200ms;
    }
    ${tagName} .mobile-menu-cont {
        position: fixed;
        inset: 0;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        transition: opacity 0.3s;
        padding-top: var(--nav-menu-height);
        background: var(--gray-brand-gradient);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1.5em;
        font-family: 'inter-medium';
        color: white;
    }
    ${tagName}[data-mobile-menu-active="true"] .mobile-menu-cont {
        pointer-events: initial;
        opacity: 1;
    }
    ${tagName} .mobile-menu-links {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.75em;
        padding: 0.75em 0.75em;
    }
    ${tagName} .mobile-menu-links a {
        width: fit-content;
        border-bottom: 4px solid var(--blue-brand);
    }

    ${tagName} .mobile-menu-cta {
        background-color: var(--blue-brand); 
        font-family: 'inter-bold', sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.75em 2em; 
        text-decoration: none;
        cursor: pointer;
        color: white; 
        border: none;
        border-radius: 25px; /* Adjust this value for more or less rounding */
        text-decoration: underline;
        text-decoration-thickness: 4px;
        text-underline-offset: 5px;
        position: relative;
        top: -20px; 
        margin: 0 auto; 

    }

    ${tagName} .dropdown-icon {
        font-size: 0.8em;
        padding-left: 10px;
        transition: transform 0.3s;
    }

    /* Mobile dropdown for "Unsere Kurse" */
    ${tagName} .kurs-dropdown-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: fit-content;
    }


    ${tagName} .dropdown-menu-mobile a::before {
        content: '\u25B6'; /* Unicode for right arrow */
        display: inline-block;
        margin-right: 10px;
        color: var(--blue-brand); /* Arrow color */
        font-size: 0.8em; /* Adjust font size if needed */
    }
    
    ${tagName} .dropdown-menu-mobile {
        display: none;
        flex-direction: column;
        padding-left: 30px;
        border-bottom: none;
        font-size: 16px;

    }

    ${tagName} .underline {
        border-bottom: 4px solid var(--blue-brand); /* Blue underline */
    }
    
    ${tagName} .dropdown-menu-mobile a {
        padding: 5px 0;
        border-bottom: none;

    }

    ${tagName}[data-mobile-dropdown-open="true"] .dropdown-menu-mobile {
        display: flex;
    }

    ${tagName}[data-mobile-dropdown-open="true"] .dropdown-icon {
        transform: rotate(180deg);
        padding-right: 10px;
    }
    
    ${tagName} .nav-bar-right-mobile-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('/assets/images/burger-menu-icon-layer.svg');
        background-size: 200% 200%;
        background-position: 50% 50%;
        transition: transform 0.2s;
        margin-top: 7px;
    }
    
    ${tagName} .nav-bar-right-mobile-layer#nav-l1 {
        transform: translateY(6px);
    }
    
    ${tagName} .nav-bar-right-mobile-layer#nav-l2 {
        background-image: url('/assets/images/burger-menu-icon-layer-white.svg');
    }
    
    ${tagName} .nav-bar-right-mobile-layer#nav-l3 {
        transform: translateY(-6px);
        background-image: url('/assets/images/burger-menu-icon-layer-blue.svg');
    }

    ${tagName}[data-mobile-menu-active="true"] .menu-label{
        transform: translateY(-8px);

    }

    ${tagName}[data-mobile-menu-active="true"] .nav-bar-right-mobile-layer#nav-l1 {
        transform: translateY(12px);
    }
    
    ${tagName}[data-mobile-menu-active="true"] .nav-bar-right-mobile-layer#nav-l3 {
        transform: translateY(-12px);
    }
`;

const renderTemplate = (announcement) => {

    return /*html*/`
        <div class="nav-bar-cont">
            <div class="announcement-cont" data-visible="${announcement ? true : false}">${announcement || ""}</div>
            <div class="main-cont">
                <a internal href="/" class="main-logo bg-img-contain" aria-label="Startseite"></a>
                <div class="button-cont">
                    <div class="menu-link menu-desktop kurs-dropdown">
                        Unsere Kurse
                        <div class="dropdown-menu">
                            <a internal href="/courses/pm">IT-Berater im Produktmanagement</a>
                            <a internal href="/courses/sales">IT-Vertriebsmanager & CRM Spezialist</a>
                        </div>
                    </div>
                    <a class="menu-link menu-desktop" internal href="/about">Über Uns</a>
                    <a class="menu-link menu-desktop" internal href="/vermittler">Für Vermittler</a>
                    <a class="menu-link menu-desktop" internal href="/#faq">Häufige Fragen</a>
                    <a cta-prevent-inner-hover target="_blank" href="${config.ctaURLS.main}" class="menu-desktop is-cta">${HeroButton(/*html*/`<span>Jetzt bewerben</span>`)}</a>
                    <div class="menu-mobile-btn menu-mobile">
                    <div class="menu-label">Menü</div>
                        <div class="nav-bar-right-mobile-layer" id="nav-l1"></div>
                        <div class="nav-bar-right-mobile-layer" id="nav-l2"></div>
                        <div class="nav-bar-right-mobile-layer" id="nav-l3"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-menu-cont">
            <div class="mobile-menu-links">
                <div class="menu-link-mobile kurs-dropdown-mobile">
                    <span class="underline">Unsere Kurse</span>
                    <div class="dropdown-icon">▼</div>
                </div>
                <div class="dropdown-menu-mobile">
                    <a internal href="/courses/pm">IT-Berater im Produktmanagement</a>
                    <a internal href="/courses/sales">IT-Vertriebsmanager & CRM Spezialist</a>
                </div>
                <a class="menu-link-mobile" internal href="/about"><span class="underline">Über Uns</span></a> <!-- Underline applied here -->
                <a class="menu-link-mobile" internal href="/vermittler"><span class="underline">Für Vermittler</span></a> <!-- Underline applied here -->
                <a class="menu-link-mobile" internal href="/#faq"><span class="underline">Häufige Fragen</span></a> <!-- Underline applied here -->
            </div>
            <a target="_blank" href="${config.ctaURLS.main}" class="mobile-menu-cta is-cta">Jetzt Bewerben</a>
        </div>
        <style>${css}</style>
    `;
};

class MainMenu extends HTMLElement {
    constructor(options){
        super();
        this.isHidden = false;
        this.mobileDropdownOpen = false; // Track the dropdown state
    }

    connectedCallback(){
        try{
            this.options = JSON.parse(atob(this.getAttribute('element-options')))
        }catch(e){
            this.options = {};
        }
        this.removeAttribute('element-options');
        this.innerHTML = renderTemplate(this.options);
        this.updateCTAs();
        this.updateStartDate();
        this.navBarElem = this.querySelector('.nav-bar-cont');
        this.navBarHeight = this.navBarElem.getBoundingClientRect().height;
        this.navBarElem.setAttribute('data-height', this.navBarHeight);
        document.documentElement.style.setProperty('--nav-menu-height', `${this.navBarHeight}px`);
        var lastScroll = window.scrollY;

        // Add a scroll event listener to toggle the shadow
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                this.navBarElem.classList.add('has-shadow');
            } else {
                this.navBarElem.classList.remove('has-shadow');
            }

            if(window.scrollY > lastScroll){
                this.hide();
            } else if(window.scrollY < lastScroll){
                this.show();
            }
            lastScroll = window.scrollY;
        }, {passive: true});

        this.mobileMenuBtn = this.querySelector('.menu-mobile-btn');
        this.mobileMenuActive = false;
        this.setAttribute('data-mobile-menu-active', this.mobileMenuActive);
        this.mobileMenuBtn.onclick = ()=>{this.handleMobileMenuBtnClick()};
        this.initMobileMenuAutoHide();
        this.initMobileDropdownToggle(); // Initialize dropdown toggle
        this.navigationEvent = eventBus.register('navigation', ()=>{
            this.updateCTAs();
            this.updateStartDate();
        });
    }

    disconnectedCallback(){
        this.navigationEvent.unregister();
    }

    handleMobileMenuBtnClick(){
        this.mobileMenuActive = !this.mobileMenuActive;
        if(this.mobileMenuActive){
            document.documentElement.classList.add('no-scroll');
        }else{
            document.documentElement.classList.remove('no-scroll');
        }
        this.setAttribute('data-mobile-menu-active', this.mobileMenuActive);
    }

    initMobileMenuAutoHide(){
        var linkElems = Array.from(this.querySelectorAll('a'));
        linkElems.forEach(linkElem=>{
            linkElem.onclick = ()=>{
                this.mobileMenuActive = true;
                this.handleMobileMenuBtnClick();
            };
        });
    }

    initMobileDropdownToggle(){
        const kursDropdown = this.querySelector('.kurs-dropdown-mobile');
        kursDropdown.addEventListener('click', () => {
            this.mobileDropdownOpen = !this.mobileDropdownOpen;
            this.setAttribute('data-mobile-dropdown-open', this.mobileDropdownOpen);
        });
    }

    hide(){
        if(this.isHidden || this.mobileMenuActive){return}
        var height = this.navBarElem.getBoundingClientRect().height;
        if(window.scrollY <= height){return}
        this.isHidden = true;
        this.navBarElem.style.transform = `translateY(-${height}px)`;
    }

    show(){
        if(!this.isHidden){return}
        this.isHidden = false;
        this.navBarElem.style.transform = `translateY(0px)`;
    }

    updateCTAs(){
        var overrideCTAPaths = {
            "/courses/pm": "pm",
            "/courses/sales": "sales"
        };
        var loc = location.pathname;
        var overrideHrefKey = overrideCTAPaths[loc];
        if(!overrideHrefKey){
            overrideHrefKey = "main";
        }
        var ctaHref = config.ctaURLS[overrideHrefKey].split('?')[0] + UTM.load('?');
        var CTAs = Array.from(this.querySelectorAll('a.is-cta'));
        CTAs.forEach(cta=>{
            cta.href = ctaHref;
        });
    }

    updateStartDate(){
        var overrideCTAPaths = {
            "/courses/pm": "pm",
            "/courses/sales": "sales"
        };
        var loc = location.pathname;
        var slug = overrideCTAPaths[loc];
        if(!slug){
            slug = "main";
        }
        var startDate = "";
        if(slug == "main"){
            startDate = config.nextStartDate;
        }else{
            startDate = config.courseData[slug].nextStartDate;
        }
        var startDateFormatted = util.formatDate(startDate);
        this.querySelector('.announcement-cont').innerText = `Nächster Starttermin: ${startDateFormatted}`;
    }
}

customElements.define(tagName, MainMenu);

function render(options){
    return `<${tagName} element-options="${btoa(JSON.stringify(options))}"></${tagName}>`;
}

export default render