const tagName = "danke-video-section";

var videoURL = "https://fast.wistia.net/embed/iframe/plllk2a66t";

var css = /*css*/`
    ${tagName} {
        display: block;
        text-align: center;
        margin-top: 1rem;
        padding: 20px;
    }
    ${tagName} iframe {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        aspect-ratio: 16 / 9;
        border-radius: 20px;
        overflow: hidden;
        border: none;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    }
    .section-message {
        font-family: 'inter-bold';
        margin-bottom: 1rem;
    }
    .light-blue-text {
        color: var(--blue-brand);
        font-size: clamp(28px, 6.18vw, 45px);
    }
    .centered-text {
        font-size: clamp(28px, 6.18vw, 45px);
        padding-bottom: 20px;
    }
`;

const renderInnerHTML = () => {
    return /*html*/`
        <div class="section-message centered-text">
            <span class="light-blue-text">Vielen Dank! <br></span>
            <span>Das hat funktioniert.</span>
        </div>
        <iframe src="${videoURL}" allow="autoplay; fullscreen" allowfullscreen></iframe>
        <style>${css}</style>
    `;
};

class DankeVideoSection extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = renderInnerHTML();
    }
}

customElements.define(tagName, DankeVideoSection);

function render() {
    return `<${tagName}></${tagName}>`;
}

export default render;
