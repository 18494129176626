import HeroButton from '/components/hero-button.js'

const tagName = "course-card-vermittler";

var css = /*css*/`
    ${tagName} {
        display: flex;
        width: 100%;
        max-width: 450px;
        aspect-ratio: 515 / 660;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        // border: 10px solid yellow;
        border-radius: 0.8rem;
        position: relative;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        // border: 1.5px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        font-size: min(18px, 3.75vw);
        overflow: hidden;
    }
    ${tagName} .text-cont {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        // background: rgba(255, 255, 0, 0.1);
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 1em;
    }
    ${tagName} .text-title {
        font-size: 2.2em;
        line-height: 1em;
        letter-spacing: -0.02em;
        font-family: 'inter-bold';
        filter: drop-shadow(0px -30px 30px rgba(255, 255, 255, 1));
    }
    ${tagName} .button {
        width: fit-content;
        padding: 1em 2em;
        color: white;
        background: var(--gray-brand-gradient);
        border-radius: 0.3em;
        cursor: pointer;
        box-shadow: 0 0.6em 0.8em rgba(0, 0, 0, 0.2);
        transition: 200ms;
    }
    ${tagName} .button span {
        font-size: 1.1em;
        border-bottom: 2px solid var(--blue-brand);
    }
    ${tagName} .text-bulletpoints {
        display: flex;
        flex-direction: column;
        gap: 0.4em;
        margin-top: 10px;
        margin-bottom: 15px;

    }
    ${tagName} .text-bulletpoint {
        font-family: 'inter-medium';
        letter-spacing: -0.02em;
        display: flex;
        gap: 0.5em;
        transform: translateX(0.3em);
    }
    ${tagName} .text-bulletpoint:before {
        content: '';
        display: flex;
        width: 1em;
        aspect-ratio: 1/1;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('/assets/images/blue-arrow-double.svg');
        transform: rotate(-90deg);
    }
    ${tagName} .text-cont a {
        margin: 0 auto;
    }
`;

const renderInnerHTML = (options)=>{
    var bulletPointsHTML = "";
    options.bulletPoints.forEach(bp=>{
        bulletPointsHTML += /*html*/`<div class="text-bulletpoint">${bp}</div>`;
    });
    return /*html*/`
        <div class="text-cont">
            <div class="text-title">${options.title}</div>
            <div class="text-bulletpoints">${bulletPointsHTML}</div>
            <div style="display: flex; justify-content: center;">
                <button class="button" style="background: none; border: none; padding: 0; cursor: pointer;" onclick="window.open('${options.ctaURL}', '_blank')">
                    ${HeroButton(/*html*/`<span>Infomaterial</span>`, {noBorder: true, shadow: true})}
                </button>
            </div>
        </div>
        <style>${css}</style>
    `;
};




class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.style.backgroundImage = `url('${this.options.imageURL}')`;
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render