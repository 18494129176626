const tagName = "karriere-hero-component";

var css = /*css*/`
  .section {
    width: 100%;
    padding: min(6vw, 40px) 0;
    display: flex;
    justify-content: center;
  }

  .wrapper {
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
  }

  .two-col-cont-karriere-hero {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Reduced gap from 30px to 15px */
    margin-bottom: 20px; /* Reduced margin-bottom */
  }

  .two-col-cont-karriere-hero>div {
    flex: 1 1 0;
  }

  .hero-3 {
    font-size: 30px;
    font-family: 'inter-bold', sans-serif;
    color: var(--blue-brand);
    text-align: center;
    margin-left: 60px;

  }

  .hero-1 {
    font-size: 30px;
    font-family: 'inter-bold', sans-serif;
    text-align: center;
    margin-left: 60px;

  }

  .hero-1 span {
    color: var(--blue-brand);
  }

  .paragraph {
    font-family: 'inter-light', sans-serif;
    font-size: 18px;
    margin-top: 15px;
    text-align: center;
    margin-left: 60px;
  }

  .karriere-hero-img {
    display: flex;
        position: relative;
        width: 100%;
        aspect-ratio: 1.177 / 1;
        background-image: url(/assets/images/josy-portrait.jpg);
        background-size: cover;
        border-radius: 0.7rem;
        max-width: 450px;
        margin: 0 auto;
  }

  .karriere-hero-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
    border-radius: 0 0 0.7rem 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px 20px;
    box-sizing: border-box;
    color: #fff;
  }

  .karriere-hero-text p {
    font-family: 'inter-medium', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.5;
  }

  .karriere-hero-text span {
    font-size: 0.9rem;
    font-weight: normal;
  }

  .contact-button {
    font-family: 'inter-medium', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background: none;
    border: 2px solid #fff;
    border-radius: 0.5rem;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 8px; /* Slightly reduced */
    transition: background-color 0.3s, color 0.3s;
  }

  .contact-button:hover {
    background-color: #fff;
    color: #000;
  }

  @media (min-width: 800px) {
    .two-col-cont-karriere-hero {
      flex-direction: row;
      gap: 30px; /* Reduced from 50px to 30px */
      align-items: center;
      justify-content: center;
    }

    .paragraph {
      text-align: left;
    }

    .hero-3,
    .hero-1 {
      text-align: left;
    }
  }

  @media (max-width: 800px) {
    .paragraph {
        margin-left: 0;
        padding-bottom: 20px;
      }
  
      .hero-3,
      .hero-1 {
        margin-left: 0;
      }
    }

`;

var template = /*html*/ `
<div class="section first-section-karriere">
  <div class="wrapper">
    <div class="two-col-cont-karriere-hero">
      <div>
        <div class="hero-3">Sie sind Vermittler|n</div>
        <div class="hero-1">und haben Fragen<br><span>zu unseren Weiterbildungen?</span></div>
        <div class="paragraph">
        Für alle Anliegen rund um uns, unsere Weiterbildungen, unser Bewerbungsprozess steht Ihnen Josephine Süß als Ihre Ansprechpartnerin zur Verfügung.
        </div>
      </div>
      <div>
        <div class="karriere-hero-img">
          <!-- Black Gradient Overlay -->
          <div class="karriere-hero-overlay">
            <div class="karriere-hero-text">
              <p>Josephin Süß<br><span>Head of Human Resources and Client Success</span></p>
              <a href="mailto:josephin.suess@franklin-institute.de">
                <button class="contact-button">Kontaktaufnahme →</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;

class KarriereHero extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });

    const container = document.createElement("div");
    container.innerHTML = template;

    const style = document.createElement("style");
    style.textContent = css;

    shadow.appendChild(style);
    shadow.appendChild(container);
  }
}

customElements.define(tagName, KarriereHero);

export default KarriereHero;
