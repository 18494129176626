const tagName = "danke-page-genaumeinkurs";

var pageTitle = "Weiterbildung beim Franklin Institute | Dein Weg zum zukunftssicheren Traumjob";
var pageDescription = "Entdecke spannende Weiterbildungen beim Franklin Institute of Applied Sciences. Wir freuen uns auf den Kontakt mit dir";

var css = /*css*/`
    ${tagName} {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-top: 1rem;
        padding: 20px;
    }
    ${tagName} .section-message {
        font-family: 'inter-bold';
        margin-bottom: 2rem; /* Increased padding after the title */
    }
    ${tagName} .light-blue-text {
        color: var(--blue-brand);
        font-size: clamp(28px, 6.18vw, 45px);
    }
    ${tagName} .black-text {
        color: black;
        font-size: clamp(28px, 6.18vw, 45px); /* Same size as light-blue-text */
        font-family: 'inter-bold'; /* Same font as light-blue-text */
    }
    ${tagName} .sub-message {
        font-size: 20px;
        font-weight: 550;
        color: var(--gray-text);
        margin-top: 10px;
        padding-bottom: 20px;
        line-height: 1.8; /* Increased line spacing */
    }
`;

const renderInnerHTML = () => {
    return /*html*/`
        <div class="section-message">
            <span class="light-blue-text">Vielen Dank </span>
            <span class="black-text">für dein Interesse!</span>
        </div>
        <div class="sub-message">
            Wir haben Deine Anfrage an GenauMeinKurs weitergegeben.<br>
            Du erhältst von Ihnen innerhalb von 24 Stunden bis zu 5 passende Weiterbildungsvorschläge via Mail.
        </div>
        <style>${css}</style>
    `;
};

class DankeGenauMeinKursPage extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = renderInnerHTML();
    }
}

customElements.define(tagName, DankeGenauMeinKursPage);

export default `<${tagName}></${tagName}>`;
