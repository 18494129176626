// blog-banner.js

const tagName = "blog-banner";

const style = /*css*/ `
  :host {
    display: block;
    font-family: sans-serif;
    color: #333;
    margin: 2rem auto;
    max-width: 800px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
  }

  .banner-content {
    padding: 1.5rem;
    background-color: #fff;
  }

  h2 {
    margin: 0 0 1rem;
    font-family: 'inter-bold', sans-serif;
    font-size: 1.6rem;
    line-height: 1.2;
    color: #333;
  }

  p {
    margin: 0 0 1.2rem;
    line-height: 1.4;
    color: #444;
  }

  .cta-button {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    background-color: #00a2ff; /* or var(--blue-brand) if you have it */
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
    font-family: 'inter-bold', sans-serif;
    transition: background 0.3s ease;
  }
  .cta-button:hover {
    background-color: #0078bf;
  }
`;

const bannerHTML = {
  allgemein: /*html*/ `
    <div class="banner-content">
      <h2>KARRIERE IM IT- UND MANAGEMENTBEREICH</h2>
      <p>Starte Deine Zukunft im Produkt- oder Vertriebsmanagement mit unserer praxisnahen Weiterbildung. 
         In wenigen Monaten lernst Du, Produkte erfolgreich zu entwickeln oder Vertriebsstrategien gezielt 
         umzusetzen – begleitet von erfahrenen Experten. Unsere Kurse sind staatlich gefördert. 
         Lass Dich beraten!</p>
      <a href="https://example.org/erstgespraech" class="cta-button">Vereinbare noch Heute Dein unverbindliches Erstgespräch</a>
    </div>
  `,

  pm: /*html*/ `
    <div class="banner-content">
      <h2>Produktmanager als Beruf</h2>
      <p>Wenn Du eine Karriere im Produktmanagement anstrebst, bieten wir Dir die passenden Weiterbildungskurse, 
         um Dich optimal vorzubereiten. In wenigen Monaten lernst Du alle wichtigen Fähigkeiten – praxisnah und 
         begleitet von erfahrenen Experten. Unsere Kurse vermitteln Dir das notwendige Know-how, um Produkte 
         erfolgreich zu planen, zu entwickeln und am Markt zu positionieren. Die Weiterbildung ist staatlich 
         gefördert. Wir beraten Dich gerne!</p>
      <a href="https://example.org/erstgespraech" class="cta-button">Vereinbare noch Heute Dein unverbindliches Erstgespräch</a>
    </div>
  `,

  sales: /*html*/ `
    <div class="banner-content">
      <h2>Vertriebsmanager als Beruf</h2>
      <p>Wenn Du Deine Zukunft im Vertriebsmanagement siehst, unterstützen wir Dich mit einer gezielten Weiterbildung. 
         Innerhalb weniger Monate eignest Du Dir praxisnah und unter Anleitung erfahrener Experten alle wesentlichen 
         Kenntnisse an, um erfolgreich im Vertrieb zu agieren. Du lernst, wie Du Verkaufsstrategien entwickelst, 
         Kunden gewinnst und Umsätze steigerst. Unsere Kurse sind staatlich gefördert. Lass Dich von uns beraten!</p>
      <a href="https://example.org/erstgespraech" class="cta-button">Vereinbare noch Heute Dein unverbindliches Erstgespräch</a>
    </div>
  `
};

class BlogBanner extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    // Read the `tag` attribute; default to "allgemein" if missing
    const categoryValue = this.getAttribute('category') || 'allgemein';
    // Select the appropriate banner
    const content = bannerHTML[categoryValue] || bannerHTML.allgemein;

    // Render the chosen banner + shared styles
    this.shadowRoot.innerHTML = `
      ${content}
      <style>${style}</style>
    `;
  }
}

customElements.define(tagName, BlogBanner);
export default `<${tagName}></${tagName}>`;
