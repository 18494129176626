import UTM from '/services/utm-carry.js'

UTM.store();

window.config = {
    nextStartDate: null,
    ctaURLS: {
        main: "https://tibiaspe9ro.typeform.com/website-leads" + UTM.load("?"),
        pm: "https://tibiaspe9ro.typeform.com/pm-neu" + UTM.load("?"),
        sales: "https://tibiaspe9ro.typeform.com/sales-neu" + UTM.load("?"),
        kontakt: "https://tibiaspe9ro.typeform.com/to/Bl0H8zlO" + UTM.load("?")
    },
    courseData: {
        pm: {
            name: "IT-Berater im Produktmanagement",
            duration: 12,
            minCertCount: 8,
            nextStartDate: "2024-12-11",
            kursnetID: 238306719
        },
        sales: {
            name: "IT-Vertriebsmanager & CRM Spezialist",
            duration: 8,
            minCertCount: 6,
            nextStartDate: "2024-12-05",
            kursnetID: 271937518
        }
    },
};

function setNextGlobalStartDate(){
    var courseSlugs = Object.keys(window.config.courseData);
    var closestDate = null;
    courseSlugs.forEach(slug=>{
        if(!closestDate){
            closestDate = window.config.courseData[slug].nextStartDate;
        }else{
            if(new Date(window.config.courseData[slug].nextStartDate) < new Date(closestDate)){
                closestDate = window.config.courseData[slug].nextStartDate;
            }
        }
    });
    window.config.nextStartDate = closestDate;
}

setNextGlobalStartDate();