const tagName = "blue-certificate-component-sales";

var css = /*css*/`
    ${tagName} {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
    }

    ${tagName} .bonus-modul-wrapper {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-left: 320px; /* Adjust this value to control the space between the left edge and the certificate */
    }

    ${tagName} .bonus-modul-plus-icon {
        width: 40px;
        height: 40px;
        background-image: url('/assets/images/plus-icon.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    ${tagName} .zertifikate-box-point {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        background: var(--light-blue-gradient);
        color: black;
        padding: 8px 8px;
        border-radius: 4px;
        gap: 10px;
        width: 220px;
        height: 120px;
        overflow: hidden;
        transition: transform 0.2s;
        cursor: grab;
        user-select: none;
    }

    ${tagName} body.has-hover .zertifikate-box-point:hover {
        transform: translateY(-5px);
    }

    ${tagName} .certificate-title {
        font-family: 'inter-medium';
        font-size: 18px;
    }

    ${tagName} .certificate-title-product-school {
        font-family: 'inter-medium';
        font-size: 17px;
    }

    ${tagName} .zertifikat-badge-ihk {
        background-image: linear-gradient(137.51deg, #FFFFFF -8.85%, #959595 152.17%);
        background: linear-gradient(137.51deg, #fbf5b7 -8.85%, #b38728 152.17%);
        border: 1px solid #bf953f;
        padding: 3px 7px;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.7);
    }

    ${tagName} .zertifikate-box-point * {
        z-index: 1;
    }

    ${tagName} .zertifikat-logo {
        position: absolute;
        top: 10px;
        right: 8px;
        width: auto;
        height: auto;
        max-width: 90px;
        max-height: 90px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    /* Specific logo styles */
    ${tagName} .zertifikat-logo-ihk {
        width: 70px;
        aspect-ratio: 2/1;
        background-image: url('/assets/images/ihk-logo.svg');
        top: 75px;
    }

    .bonus-modul-disclaimer {
        max-width: 800px;
        text-align: center;
        font-family: 'inter-light';
        margin: 0 auto;
        margin-top: 30px;
        font-size: 18px;
        margin-bottom: 20px;
    }

    @keyframes shine {
        0% {
            left: -100%;
        }
        25% {
            left: 100%;
        }
        100% {
            left: 100%;
        }
    }

    @media (max-width: 1119px) {
        ${tagName} .zertifikate-box {
            grid-template-columns: 1fr 1fr !important; /* Two columns for screens narrower than 1119px */
        }
    }

    @media (max-width: 599px) {
        ${tagName} .zertifikate-box {
            grid-template-columns: 1fr !important; /* One column on very small screens */
        }
    }

    @media (max-width: 809px) {
        ${tagName} .bonus-modul-wrapper {
            margin-left: 40px; /* Adjust this value to control the space between the left edge and the certificate */
        }
    }
`;

const renderInnerHTML = (options) => {
    // Determine the logo class based on the logoURL or another property
    let logoClass = '';
    if (options.logoURL.includes('ihk-logo.svg')) {
        logoClass = 'zertifikat-logo-ihk';
    }

    // Determine the badge class based on the badgeText content
    let badgeClass = '';
    if (options.badgeText.includes('+ IHK-Zertifikat')) {
        badgeClass = 'zertifikat-badge-ihk';
    }

    // Determine the title class based on the title content
    let titleClass = 'certificate-title';
    if (options.title.includes('User Research Zertifikat der Interaction Design Foundation')) {
        titleClass = 'certificate-title-product-school';
    }

    return /*html*/`
        <div class="bonus-modul-wrapper">
            <div class="bonus-modul-plus-icon"></div>
            <div class="zertifikate-box-point">
                <div class="${badgeClass}">${options.badgeText}</div>
                <div class="${titleClass}">${options.title}</div>
                <div class="zertifikat-logo ${logoClass}"></div>
            </div>
        </div>
        <style>${css}</style>
    `;
};

class CertificateComponentSales extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        this.options = JSON.parse(this.getAttribute('element-options'));
        console.log('Received data:', this.options);  // Log the data
        this.innerHTML = renderInnerHTML(this.options);
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CertificateComponentSales);

function render(options){
    return `<${tagName} element-options='${JSON.stringify(options)}'></${tagName}>`;
}

export default render;