const tagName = "voraussetzungen-component";

// CSS to be applied globally
var VoraussetzungenCss = /*css*/ `
${tagName} .fcc-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px; /* Adjusted padding */
    background-color: transparent;
}

${tagName} .fcc-svg-container {
    width: 100%;
    max-width: 1100px; 
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

${tagName} svg {
    width: 100%;
    height: auto;
    max-width: 100%; /* Ensures SVG scales within its container */
    overflow: visible;
}

/* Desktop hover effects */
${tagName} #desktop-01,
#desktop-02,
#desktop-03,
#desktop-04,
#desktop-05,
#desktop-06 {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.15));
    transition: transform 0.3s ease;
    transform-origin: center;
}

${tagName} #desktop-01:hover {
    transform: translateY(-5px) !important;
}

${tagName} #desktop-02:hover {
    transform: translateX(5px);
}

${tagName} #desktop-03:hover {
    transform: translateX(-5px) !important;
}

${tagName} #desktop-04:hover {
    transform: translateX(-5px);
}

${tagName} #desktop-05:hover {
    transform: translateX(5px);
}


/* Title and text styles */
${tagName} .centered-text {
    text-align: center;
    margin-bottom: 0.1em;
    font-family: 'inter-bold';
    font-size: clamp(28px, 6.18vw, 45px);
    padding-top: 50px;
}

/* Mobile adjustments */
@media (max-width: 630px) {
    ${tagName} .fcc-svg-container {
        display: none;
    }

    ${tagName} .centered-text {
        padding-top: 30px;
    }

    ${tagName} .fcc-container {
        display: none;
    }

    ${tagName} .fcc-svg-container-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 350px; /* Smaller max width for mobile view */
        margin: 0 auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    ${tagName} svg {
        width: 100%;
        height: auto;
    }
}

/* Hide mobile SVG on larger screens */
@media (min-width: 631px) {
    ${tagName} .fcc-svg-container-mobile {
        display: none;
    }
}
`;

// HTML template for desktop and mobile SVG containers
var VoraussetzungenTemplate = /*html*/ `
<head>
  <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap" rel="stylesheet">
</head>
<div class="section-message centered-text">
    <span style="font-size: clamp(28px, 6.18vw, 45px); font-family: 'inter-bold';">
        Voraussetzungen der Teilnehmer
    </span>
</div>

<!-- Desktop SVG container -->
<section class="fcc-container">
    <div class="fcc-svg-container" id="desktop-svg-container"></div>
</section>

<!-- Mobile SVG container -->
<section class="fcc-container-mobile">
    <div class="fcc-svg-container-mobile" id="mobile-svg-container"></div>
</section>
`;

// Function to load the external SVG files
function loadSVG(svgUrl, containerElement) {
    fetch(svgUrl)
        .then(response => response.text())
        .then(svgData => {
            containerElement.innerHTML = svgData;
            const svgElement = containerElement.querySelector('svg');
            if (svgElement) {
                svgElement.style.width = "100%";
                svgElement.style.height = "auto";
            }
        })
        .catch(error => console.error('Error loading SVG:', error));
}

// Define the custom element without using Shadow DOM
class Voraussetzungen extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        // Append the template content
        this.innerHTML = VoraussetzungenTemplate;

        // Append the CSS styles directly to the document if not already added
        if (!document.getElementById('voraussetzungen-styles')) {
            const style = document.createElement("style");
            style.id = 'voraussetzungen-styles';
            style.textContent = VoraussetzungenCss;
            document.head.appendChild(style);
        }

        // Load the desktop and mobile SVGs
        const desktopSvgContainer = this.querySelector('#desktop-svg-container');
        const mobileSvgContainer = this.querySelector('#mobile-svg-container');

        loadSVG('/assets/images/Voraussetzungen.svg', desktopSvgContainer);
        loadSVG('/assets/images/Voraussetzungen_mobile.svg', mobileSvgContainer);
    }
}

customElements.define(tagName, Voraussetzungen);

export default Voraussetzungen;
