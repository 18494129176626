// blog-carousel-component.js
import BlogCard from '/components/blog-card-component.js';
import { getAllBlogPosts } from '/services/blog.service.js';
import { slugify } from '/services/util.js';

const tagName = 'blog-carousel-component';

const css = /*css*/`
:host {
  display: block;
  width: 100%;
}

.carousel-header {
  font-size: 1.8em;
  font-family: 'inter-bold';
  margin-bottom: -20px;
  color: #333;
  text-align: center;
}

/* The wrapper stays centered and has a max-width of 1040px */
.carousel-wrapper {
  position: relative;
  display: flex;
  justify-content: center; 
  align-items: center;     
  width: 1140px;           
  margin: 0 auto;          
  padding: 25px;
  left: 50%;               
  transform: translateX(-50%);
}

.carousel-container {
  display: flex;
  gap: 26px;             
  overflow: hidden;      
  scroll-behavior: smooth;
  padding: 30px;
  width: 100%;           
  width: 998px;      
  margin: 0 auto;        
}

.carousel-item {
  flex: 0 0 auto;         
  width: 320px;
}

/* Hide scrollbar (in case someone tries to scroll horizontally with trackpad) */
.carousel-container::-webkit-scrollbar {
  display: none;
}

/* Arrow buttons (desktop & mobile) */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 2;
  font-size: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.arrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}

${tagName} a {
  display: block;
  text-decoration: none; /* Removes underline from the link */
}

${tagName} a:hover blog-card-component {
  transform: scale(1.01); /* Scale on hover */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Add hover shadow */
}

/*Mobile Styles (max-width: 490px)*/
@media (max-width: 490px) {

  .carousel-header {
    margin-bottom: -5px;
  }

  .carousel-wrapper {
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .carousel-container {
    width: 95%;
    gap: 55px;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    box-sizing: border-box;
    padding: 20px 20px;
  }

  .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex: 0 0 100%;
    scroll-snap-align: center;
    
  }

  .arrow {
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
  }
  .arrow-left {
    left: 4px;
  }
  .arrow-right {
    right: 4px;
  }
}
`;

class BlogCarouselComponent extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    this.renderSkeleton(); // Optional placeholder while loading

    // If we want to exclude the current blog by ID, we read `exclude-id`:
    const excludeId = this.getAttribute('exclude-id') || '';

    try {
      // Load all blog posts
      const allBlogs = await getAllBlogPosts();
      if (!Array.isArray(allBlogs)) {
        throw new Error('Invalid blog data format.');
      }
      // Filter out the excluded blog if needed
      const filteredBlogs = allBlogs.filter(blog => String(blog.id) !== excludeId);

      console.log('Number of blog posts in carousel after filtering:', filteredBlogs.length);

      // Sort blogs by date_published descending
      const sortedBlogs = filteredBlogs.sort((a, b) => new Date(b.date_published) - new Date(a.date_published));

      // Build final HTML
      this.innerHTML = `
        <div class="carousel-header">Weitere Artikel</div>
        <div class="carousel-wrapper">
          <button class="arrow arrow-left" aria-label="Scroll Left">${getLeftArrowSVG()}</button>
          <div class="carousel-container">
            ${sortedBlogs
              .map((blog) => {
                const safeTitle = blog.title?.trim() || "Untitled Post";
                const safeSummary = blog.summary?.trim() || "No summary available";
                const safeReadTime = blog.read_time && blog.read_time > 0 
                  ? `${blog.read_time} min read` 
                  : "N/A";
                const safeThumbnail = blog.thumbnail?.trim() 
                  ? blog.thumbnail 
                  : "https://via.placeholder.com/300";
                
                // Generate a slug from the blog title
                const slug = slugify(safeTitle);

                return `
                <div class="carousel-item">
                  <a internal href="/blog/${slug}">
                    ${BlogCard({
                      title: safeTitle,
                      thumbnail: safeThumbnail,
                      summary: safeSummary,
                      read_time: safeReadTime,
                      link: `/blog/${slug}`
                    })}
                  </a>
                </div>
              `;
            })
            .join('')}
          </div>
          <button class="arrow arrow-right" aria-label="Scroll Right">${getRightArrowSVG()}</button>
        </div>
        <style>${css}</style>
      `;

      this.setupArrows();
    } catch (error) {
      console.error('Error loading blog posts:', error);
      this.innerHTML = `<p style="color: red; text-align: center;">Failed to load blog posts.</p>`;
    }
  }

  renderSkeleton() {
    this.innerHTML = `
      <div class="carousel-header">Weitere Artikel</div>
      <div class="carousel-wrapper">
        <div class="carousel-container">
          ${Array(3).fill(0).map(() => `
            <div class="carousel-item">
              <div style="width: 320px; height: 500px; background: #f0f0f0; border-radius: 8px;"></div>
            </div>
          `).join('')}
        </div>
      </div>
      <style>${css}</style>
    `;
  }

  setupArrows() {
    const container = this.querySelector('.carousel-container');
    const btnLeft = this.querySelector('.arrow-left');
    const btnRight = this.querySelector('.arrow-right');

    if (!container || !btnLeft || !btnRight) {
      console.error('Carousel elements not found.');
      return;
    }

    const getScrollAmount = () => {
      // We use the width of the first item + the gap
      const containerStyle = window.getComputedStyle(container);
      const gap = parseInt(containerStyle.gap) || 26; // Default gap if not set
      const firstItem = container.querySelector('.carousel-item');
      const itemWidth = firstItem ? firstItem.offsetWidth : 320; // Default to 320 if not found
      const amount = itemWidth + gap + 10;
      console.log('Calculated scrollAmount:', amount);
      return amount; // e.g. 336
    };

    let scrollAmount = getScrollAmount();

    btnLeft.addEventListener('click', () => {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    });

    btnRight.addEventListener('click', () => {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    });

    // Optional: Disable arrows when at the ends
    const updateArrows = () => {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      btnLeft.disabled = container.scrollLeft <= 0;
      btnRight.disabled = container.scrollLeft >= (maxScrollLeft - 1);

      // Update aria-disabled for accessibility
      btnLeft.setAttribute('aria-disabled', btnLeft.disabled);
      btnRight.setAttribute('aria-disabled', btnRight.disabled);

      // Style changes
      btnLeft.style.opacity = btnLeft.disabled ? '0.5' : '1';
      btnRight.style.opacity = btnRight.disabled ? '0.5' : '1';
      btnLeft.style.cursor = btnLeft.disabled ? 'not-allowed' : 'pointer';
      btnRight.style.cursor = btnRight.disabled ? 'not-allowed' : 'pointer';

      console.log('Left arrow disabled:', btnLeft.disabled, 'Right arrow disabled:', btnRight.disabled);
    };

    // Initial check
    updateArrows();

    // Update on scroll
    container.addEventListener('scroll', updateArrows);

    // Update on window resize
    window.addEventListener('resize', () => {
      scrollAmount = getScrollAmount();
      updateArrows();
    });
  }
}

// Helper functions to define SVG icons
function getLeftArrowSVG() {
  return `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" 
         xmlns="http://www.w3.org/2000/svg">
      <path d="M15 18L9 12L15 6" 
            stroke="currentColor" 
            stroke-width="2" 
            stroke-linecap="round" 
            stroke-linejoin="round"/>
    </svg>
  `;
}

function getRightArrowSVG() {
  return `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" 
         xmlns="http://www.w3.org/2000/svg">
      <path d="M9 18L15 12L9 6" 
            stroke="currentColor" 
            stroke-width="2" 
            stroke-linecap="round" 
            stroke-linejoin="round"/>
    </svg>
  `;
}

customElements.define(tagName, BlogCarouselComponent);

export default `<${tagName}></${tagName}>`;
