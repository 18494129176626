import KontaktBoxesComponent from "../components/kontakt-boxes-component.js";
import StandortenComponent from "../sections/standorten-section.js";
import KarriereHeroComponent from "../components/karriere-hero-component.js";

const tagName = "kontakt-page";

var pageTitle = "Kontakt | Franklin Institute of Applied Sciences";
var pageDescription = "Nimm Kontakt mit uns auf und finde heraus, welche Weiterbildung zu dir am besten passt.";

var css = /*css*/`
.title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 10px;
    margin-bottom: -10px;
  }
`;

function template(){
    return /*html*/`

    <div class="title">Kontakt</div>
    <kontakt-boxes-component></kontakt-boxes-component>

    <karriere-hero-component></karriere-hero-component>

    <div class="title", id="standorten">Unsere Standorte</div>
    <standorten-section></standorten-section>    

    <style>${css}</style>
        
    `;
}

// Define the main page component
class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template();
    }
}

// Register the main page component
customElements.define(tagName, Page);

// Export the main component if needed
export default `<${tagName}></${tagName}>`;