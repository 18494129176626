import Danke4SchritteComponent from '../components/danke-4-schritte-component.js';
import DankeShorterVideoSection from '/sections/danke-shorter-video-section.js';
import FeedbackSection from '/sections/feedback-section.js';

const tagName = "danke-sales-page";

var pageTitle = "Weiterbildung beim Franklin Institute | Dein Weg zum zukunftssicheren Traumjob";
var pageDescription = "Entdecke spannende Weiterbildungen beim Franklin Institute of Applied Sciences. Wir freuen uns auf den Kontakt mir dir";

// Define the options object globally
var options = {
    ctas: [
        {
            label: "Jetzt starten!",
            href: config.ctaURLS.eignungstestsales,
            internal: false,
            newTab: true
        }
    ]
};

var css = /*css*/`
/* Styling */
.above-cont {
    display: flex;
    justify-content: center;
    gap: 2em;
    align-items: center;
    margin-top: 3em;
    margin-bottom: 1.2em;
}

.above-cont-text {
    text-transform: uppercase;
    text-align: center;
    font-family: 'inter-bold', sans-serif;
    font-size: 1.6em;
}

.above-cont-text span {
    color: var(--blue-brand);
}

.double-arrow-down {
    width: 2.5em;
    height: 2.5em;
    background-image: url('/assets/images/blue-arrow-double.svg');
    background-size: 100% 100%;
}

/* Centering the button */
.hero-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-bottom: 60px;
}

.hero-button a {
    padding: 0.6em 3em; /* Increased padding for larger button size */
    font-size: 1.7em; /* Increased font size */
    font-family: 'inter-bold', sans-serif;
    background-color: var(--blue-brand); /* Brand blue background */
    color: white; /* White text */
    border: 3px solid var(--blue-brand); /* Slightly thicker border */
    border-radius: 30px; /* More rounded corners */
    text-decoration: none;
    transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.hero-button a:hover {
    background-color: white; /* Invert colors on hover */
    color: var(--blue-brand); /* Blue text */
    transform: scale(0.9); /* Slightly enlarge on hover */
}
`;

var template = /*html*/`
    <style>${css}</style>

    <!-- Video Section -->
    <danke-shorter-video-section></danke-shorter-video-section>

    <!-- 4-Schritte Section -->
    <danke-4-schritte-component></danke-4-schritte-component>

    <!-- Above-Cont Section -->
    <div class="above-cont">
        <div class="double-arrow-down"></div>
        <div class="above-cont-text">Zum<br><span>Eignungstest</span></div>
        <div class="double-arrow-down"></div>
    </div>

    <!-- Button Section -->
    <div class="hero-button">
        <a 
            class="button" 
            ${options.ctas[0].internal ? "internal" : ""} 
            ${options.ctas[0].newTab ? 'target="_blank"' : ""} 
            href="${options.ctas[0].href}">
            <span>${options.ctas[0].label}</span>
        </a>
    </div>

    <!-- Feedback Section -->
    <div class="feedback-section-wrapper">
        ${FeedbackSection}
    </div>
`;

class DankeSalesPage extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = template;
    }
}

customElements.define(tagName, DankeSalesPage);

export default `<${tagName}></${tagName}>`;
