const tagName = "vermittler-hero-component";

var vermittlerHeroComponentCss = /*css*/`
    :host {
        display: block;
        position: relative;
        width: 100%;
        background-color: transparent;
    }
    
    .blue-line {
        height: 0.5rem;
        background: var(--blue-wide-gradient);
        position: absolute;
        left: 0;
        right: 0;
    }

    .blue-line.top {
        top: 0px;
    }

    .blue-line.bottom {
        bottom: 0px;
        z-index: 2;
    }

    .vermittler-hero-mobile-img {
        background-image: url('/assets/images/vermittler-hero-img-desktop-new.jpg');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 200px;
        position: relative;
        background-color: transparent;
        padding: 0;
        margin: 0;
    }

    .vermittler-hero-img-mobile-cont {
        display: none;
        padding: 0;
        margin: 0;
        background-color: transparent;
        overflow: visible;
    }

    .vermittler-hero-desktop-cont {
        position: relative;
        display: block;
        overflow: visible;
    }

    .vermittler-hero-desktop-img {
        background-image: url('/assets/images/vermittler-hero-img-desktop-new.jpg');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 400px;
        position: relative;
    }

    .vermittler-hero-mobile-text {
        position: absolute;
        bottom: 50%;
        left: 35%;  
        transform: translate(-50%, 50%); 
        color: white;
        font-family: 'inter-light', sans-serif;
        font-size: clamp(25px, 2vw, 30px); /* Use clamp for responsiveness */
        max-width: 36vw;
    }

    .vermittler-hero-mobile-text span {
        font-family: 'inter-bold', sans-serif;
        font-size: clamp(14px, 1.8vw, 22px); /* Ensure responsive font for span */
    }

    .vermittler-hero-desktop-fade {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 60%);
    }    

    .vermittler-hero-desktop-text {
        position: absolute;
        bottom: 50%; 
        left: 35%;   
        transform: translate(-50%, 50%); 
        color: white;
        font-family: 'inter-light', sans-serif;
        font-size: clamp(25px, 2vw, 30px); /* Use clamp for responsiveness */
        max-width: 36vw;
    }

    .vermittler-hero-desktop-text span {
        font-family: 'inter-bold', sans-serif;
        font-size: clamp(14px, 1.8vw, 22px); /* Ensure responsive font for span */
    }

    @media (max-width: 710px) {
        .vermittler-hero-img-mobile-cont {
            display: block;
        }

        .vermittler-hero-img-mobile {
            width: 100%;
            height: auto;
            background-size: cover;
        }

        .vermittler-hero-desktop-img {
            display: none;
        }

        .vermittler-hero-mobile-text {
            bottom: 50%; 
            left: 33%;   
            transform: translate(-50%, 50%); /* Adjust positioning to truly center */
            font-size: clamp(6px, 3.4vw, 18px); /* Responsive font size with min/max */
            width: 45%; 
            max-width: 90vw;
        }

        .vermittler-hero-mobile-text span {
            font-family: 'inter-bold', sans-serif;
            font-size: clamp(8px, 2.5vw, 16px); /* Ensure responsive font for span */
        }

        .vermittler-hero-desktop-text {
            display: none;
        }
    }
`;

// Create a new CSSStyleSheet
const styleSheet = new CSSStyleSheet();
styleSheet.replaceSync(vermittlerHeroComponentCss);

const getTemplate = () => /*html*/`
    <div class="vermittler-hero-img-mobile-cont">
        <div class="vermittler-hero-mobile-img">
            <div class="vermittler-hero-desktop-fade"></div>
        </div>
        <div class="vermittler-hero-mobile-text">
            "Unser Anspruch ist es, mindestens 90% unserer Teilnehmer erfolgreich in einen zukunftssicheren Job in der IT-Branche zu vermitteln."<br><br>
            <span>Dr. Emil Kendziorra<br>Bildungsbeauftragter Franklin IAS</span>
        </div>
        <div class="blue-line bottom"></div> 
    </div>
    <div class="vermittler-hero-desktop-cont">
        <div class="vermittler-hero-desktop-img">
            <div class="vermittler-hero-desktop-fade"></div>
        </div>
        <div class="vermittler-hero-desktop-text">
            "Unser Anspruch ist es, mindestens 90% unserer Teilnehmer erfolgreich in einen zukunftssicheren Job in der IT-Branche zu vermitteln."<br><br>
            <span>Dr. Emil Kendziorra<br>Bildungsbeauftragter Franklin IAS</span>
        </div>
        <div class="blue-line bottom"></div> 
    </div>
`;

class HeroComponent extends HTMLElement {
    constructor() {
        super();
        // Attach shadow DOM
        const shadow = this.attachShadow({ mode: 'open' });

        // Use adoptedStyleSheets to apply the styles
        shadow.adoptedStyleSheets = [styleSheet];

        // Create and append the template
        const container = document.createElement('div');
        container.innerHTML = getTemplate();
        shadow.appendChild(container);
    }
}

// Register the custom element
customElements.define(tagName, HeroComponent);

export default HeroComponent;
