import './event-bus.js'

const routes = [
	"/",
	"/about",
	"/vermittler",
	"/partner",
	"/404",
	"/not-found.html",
	"/career",
	"/courses/pm",
	"/courses/sales",
	"/impressum",
	"/datenschutz",
	"/danke",
	"/danke-sales",
	"/danke-weiterbildungen",
	"/danke-angestellt-genaumeinkurs",
];

const redirects = {
	"/erstgespraech": "/courses/pm"
};

function route(route, skipEvent=false){
	if(!routes.includes(route.split("#")[0].split('?')[0])){
		location.href = "/404";
		return
	}
	history.pushState({}, null, route);
	if(!skipEvent){
		var eventName = 'navigation';
		eventBus.emit(eventName);
	}
}

function setQueryString(query){
	var route = location.pathname + "?" + query;
	history.pushState({}, null, route);
	var eventName = 'navigation-query';
	eventBus.emit(eventName);
}

function scrollToTop(instant=false){
	if(instant){
		document.documentElement.style.scrollBehavior = "initial";
		setTimeout(()=>{
			document.documentElement.style.scrollBehavior = "smooth";
		}, 500);
		setTimeout(()=>{
			window.scrollTo(0, 0);
		}, 10);
	}else{
		window.scrollTo(0, 0);
	}
}

function scrollToElement(elemSelector){
	var elem = document.querySelector(elemSelector);
	if(!elem){
		return
	}
	scrollTo(0, window.scrollY + elem.getBoundingClientRect().top - document.querySelector('main-menu .nav-bar-cont').getBoundingClientRect().height);
}

function handleClicks(e){
	// console.warn("@router.js handleClicks SCROLL DELAY IMPLEMENTATION MIGHT BE NEEDED");
	if(e.target.tagName != 'A' || !e.target.hasAttribute('internal')){
		return
	}
	e.preventDefault();
	var useHREF = e.target.getAttribute('href');
	if(useHREF.includes(location.origin)){
		useHREF = useHREF.split(location.origin)[1];
	}
	var targetRoute = useHREF.split("#")[0];
	var targetRouteWithHash = useHREF;
	var hash = useHREF.split("#")[1];
	var hashElem = null;
	if(targetRoute == location.pathname){
		if(targetRouteWithHash != location.pathname + location.hash){
			route(targetRouteWithHash, true);
		}
		if(hash){
			hashElem = document.querySelector('#' + hash);
		}
		if(!hashElem){
			scrollToTop();
		}else{
			scrollToElement('#' + hash);
		}
	}else{
		route(targetRouteWithHash);
		if(hash){
			hashElem = document.querySelector('#' + hash);
		}
		if(!hashElem){
			scrollToTop(true);
		}else{
			scrollToElement('#' + hash);
		}
	}
}

function init(){
	window.addEventListener('popstate', (e)=>{
		eventBus.emit('navigation');
	});

	var loc = location.pathname;
	
	if(Object.keys(redirects).includes(loc)){
		var newLoc = redirects[loc] + location.search;
		route(redirects[loc] + location.search);
		return
	}
	
	if(!routes.includes(loc)){
		location.href = "/404";
		return
	}
	
	window.addEventListener('click', handleClicks);
	
	window.addEventListener('message', (e)=>{
		eventBus.emit('message', e.data);
	});
}

init();

export default {
	route,
	setQueryString
}