const tagName = "azav-verifiziert-section";

var css = /*css*/`
${tagName} {
    display: block;
    font-family: 'inter-medium';
}
${tagName} .grid-outer-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px; 
    padding-top: 90px;
    padding-bottom: 20px;
}

${tagName} .section-grid {
    max-width: 900px; /* Adjusted to match the width of the boxes above */
    display: grid;
    gap: 40px;
    grid-template-columns: 2fr 1fr;
}
${tagName} .text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.6em;
}
${tagName} .section-title {
    font-family: 'inter-bold';
    font-size: 1.5rem;
    margin-bottom: 1em;
}
${tagName} .section-text {
    font-size: 1rem;
    margin-bottom: 1.5em;
}
${tagName} .info-img {
    background-image: url('/assets/images/azav-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
}

/* Responsive adjustments */

@media (max-width: 956px) {

    ${tagName} .grid-outer-cont {
        padding: 40px 40px; /* Further reduce padding on smaller screens */
        padding-top: 90px;
        padding-bottom: 20px;
    }

    ${tagName} .section-grid {
        max-width: 700px;
    }


}
@media(max-width: 899px){
    ${tagName} .section-grid {
        grid-template-columns: 1fr;
    }
    ${tagName} .info-img {
        height: 200px;
    }
}

@media (max-width: 500px) {
    ${tagName} .grid-outer-cont {
        padding: 40px 40px; /* Further reduce padding on smaller screens */
        padding-bottom: 20px;
    }

    ${tagName} .info-img {
        padding-left: 40px;
}
}

`;

const renderInnerHTML = () => {
    return /*html*/`
    <div class="grid-outer-cont">
        <div class="section-grid">
            <div class="text-content">
                <div class="section-title">AZAV verifiziert</div>
                <div class="section-text">Franklin Institute of Applied Sciences ist ein AZAV-zertifiziertes Institut, das sich auf die Ausbildung in technischen Berufen spezialisiert hat. Wir arbeiten seit vielen Jahren mit dem Jobcenter und der Agentur für Arbeit zusammen, um Ihren Klient*innen durch den Bildungsgutschein oder AVGS optimalen Zugang zu unseren praxisnahen Weiterbildungen zu verschaffen.</div>
            </div>
            <div class="info-img"></div>
        </div>
    </div>
    <style>${css}</style>
    `;
};

class AzavVerifiziertSection extends HTMLElement {
    connectedCallback() {
        this.innerHTML = renderInnerHTML(); 
    }
}
customElements.define("azav-verifiziert-section", AzavVerifiziertSection);


function render(){
    return `<${tagName}></${tagName}>`;
}

export default render;
