function checkIsFriendCode(code){
    var validChars = "ABCDEFGHKLMNPQRSTUVWXYZ";
    if(typeof code != 'string'){
        return false
    }
    if(code.length != 6){
        return false
    }
    for(let i=0; i<code.length; i++){
        if(!validChars.includes(code[i].toUpperCase())){
            return false
        }
    }
    return true
}

function getLocalCookieValue(cookieName){
    try{
        return document.cookie.split("; ").filter(cookie => cookie.startsWith(`${cookieName}=`))[0].split("=")[1];
    }catch(e){
        return null
    }
}

function getFbxData(){
    var fbpValue = getLocalCookieValue("_fbp");
    var fbcValue = getLocalCookieValue("_fbc");
    var res = {};
    if(fbpValue){
        res.fbp = fbpValue;
    }
    if(fbcValue){
        res.fbc = fbcValue;
    }else{
        var queryStr = location.search;
        if(queryStr){
            queryStr = queryStr.slice(1);
            var params = queryStr.split("&");
            var fbcParam = params.filter(param => param.startsWith('fbclid='))[0];
            if(fbcParam){
                var fbcParamValue = fbcParam.split("=")[1];
                var fbcCookieValue = `fb.1.${new Date().getTime()}.${fbcParamValue}`;
                res.fbc = fbcCookieValue;
            }
        }
    }
    return res
}

function store(options = null){
    try{
        var qString = location.href.split('?')[1]
        var res = {};
        if(qString){
            var params = qString.split('&');
            params.forEach(param=>{
                if(param.includes('utm_source')){res.utm_source = param.split('=')[1]}
                if(param.includes('utm_medium')){res.utm_medium = param.split('=')[1]}
                if(param.includes('utm_campaign')){res.utm_campaign = param.split('=')[1]}
                if(param.includes('utm_content')){res.utm_content = param.split('=')[1]}
                if(param.includes('fc')){
                    var value = param.split('=')[1];
                    if(checkIsFriendCode(value)){
                        res.fc = value
                    }
                }
            });
        }
        if(options){
            if(options.meinnowCourseData){
                if(options.meinnowCourseData.id){
                    res.meinnow_course_id = options.meinnowCourseData.id;
                }
                if(options.meinnowCourseData.type){
                    res.meinnow_course_type = options.meinnowCourseData.type;
                }
                if(options.meinnowCourseData.duration){
                    res.meinnow_course_duration = options.meinnowCourseData.duration;
                }
            }
            if(options.blogData){
                if(options.blogData.id){
                    res.website_blog_id = options.blogData.id;
                }
                if(options.blogData.slug){
                    res.website_blog_slug = options.blogData.slug;
                }
            }
        }
        var fbxData = getFbxData();
        if(fbxData.fbp){
            res.fbx_p = fbxData.fbp;
        }
        if(fbxData.fbc){
            res.fbx_c = fbxData.fbc;
        }
        if(Object.keys(res).length == 0){return}
        var resString = btoa(btoa(JSON.stringify(res)));
        sessionStorage.setItem('utm_carry', resString);
    }catch(e){
        console.log("err @ utm-carry: " + e);
    }
}

function load(prefix=""){
    var res = "";
    var defaultUTMSource = "website-cta";
    try{
        var utmString = atob(atob(sessionStorage.getItem('utm_carry')));
        var utmObject = JSON.parse(utmString);
        if(!utmObject.utm_source){
            utmObject.utm_source = defaultUTMSource;
        }else{
            utmObject.utm_source = `${defaultUTMSource}__${utmObject.utm_source}`;
        }
        var hashedParamNames = ["fc", "meinnow_course_id", "meinnow_course_type", "meinnow_course_duration", "fbx_p", "fbx_c", "website_blog_id", "website_blog_slug"];
        var queryString = "";
        var hashString = "";
        Object.keys(utmObject).forEach(key=>{
            if(!hashedParamNames.includes(key)){
                queryString += `${key}=${utmObject[key]}&`;
            }else{
                var renamedKey = "" + key;
                if(key == "fc"){
                    var renamedKey = "friend_code";
                }
                hashString += `${renamedKey}=${utmObject[key]}&`;
            }
        });
        if(queryString.slice(-1) == '&'){
            queryString = queryString.slice(0, -1);
        }
        if(hashString.slice(-1) == '&'){
            hashString = hashString.slice(0, -1);
        }
        res = prefix + queryString;
        if(hashString.length > 0){
            res += "#" + hashString;
        }
        return res
    }catch(err){
        res = prefix + "utm_source=" + defaultUTMSource;
        return res;
    }
}

function getSession(){
    var isNew = false;
    var sID = sessionStorage.getItem('fias_sid');
    if(!sID){
        isNew = true;
        var sID = crypto.randomUUID();
        sessionStorage.setItem('fias_sid', sID);
    }
    return {isNew, id: sID}
}

export default {store, load, getSession}