import { getAllBlogPosts, getBlogPostById } from '/services/blog.service.js';
import '/components/blog-carousel-component.js';
import { slugify } from '/services/util.js';
import UTM from '/services/utm-carry.js';
import '/components/blog-banner.js';


const tagName = "blog-individual-page";

var css = /*css*/`
  ${tagName} {
    display: block;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
  }

  ${tagName} .blog-title {
    font-size: 2.5em;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
    padding-bottom: 10px;
  }

  ${tagName} .blog-meta {
    font-size: 1.1em;
    color: #777;
    text-align: center;
    margin-bottom: 20px;
  }

  ${tagName} .blog-summary {
    font-size: 1.1em;
    color: #444;
    margin-bottom: 20px;
    text-align: center;
    font-style: italic;
  }

  ${tagName} .blog-summary a,
  ${tagName} .section-summary a {
    color: var(--blue-brand);
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
    text-underline-offset: 2px; 
  } 

  ${tagName} .thumbnail {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  ${tagName} .section {
    margin-bottom: 2em;
  }

  ${tagName} .section-header {
    font-size: 1.8em;
    font-weight: bold;
    color: #333;
  }

  ${tagName} .section-summary {
    margin-bottom: 1em;
    color: #444;
    font-size: 1.05em;
  }

  ${tagName} .section-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    margin-top: 1em;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }

  /* Skeleton (loading) styles */
  ${tagName} .skeleton-title {
    height: 2.5em;
    background: #ccc;
    border-radius: 0.5em;
    margin-bottom: 14px;
    margin-top: 35px;
    animation: shimmer 1.5s infinite;
  }

  ${tagName} .skeleton-title-bar {
    height: 2.5em;
    width: 90%;
    background: #ddd;
    border-radius: 0.5em;
    margin: 0 auto 20px;
    animation: shimmer 1.5s infinite;
    margin-bottom: 20px;
  }

  ${tagName} .skeleton-meta {
    height: 1.5em;
    width: 35%;
    background: #ddd;
    border-radius: 0.5em;
    margin: 0 auto 20px;
    animation: shimmer 1.5s infinite;
  }

  ${tagName} .skeleton-summary {
    height: 1.5em;
    width: 100%;
    background: #ddd;
    border-radius: 0.5em;
    margin: 0 auto 10px;
    animation: shimmer 1.5s infinite;
  }

  ${tagName} .skeleton-thumbnail {
    width: 100%;
    height: 400px;
    background: #eee;
    border-radius: 0.4em;
    margin-bottom: 20px;
    animation: shimmer 1.5s infinite;
    margin-top: 20px;
  }

  ${tagName} .skeleton-paragraph {
    height: 1.2em;
    background: #ddd;
    border-radius: 0.5em;
    margin-bottom: 10px;
    animation: shimmer 1.5s infinite;
  }

  @keyframes shimmer {
    0% { background-position: -200px 0; }
    100% { background-position: 200px 0; }
  }

  /* Styles for Above-Cont Section */
  .above-cont {
    display: flex;
    justify-content: center;
    gap: 2em;
    align-items: center;
    margin-top: 3em;
    margin-bottom: 1.2em;
  }

  .above-cont-text {
    text-transform: uppercase;
    text-align: center;
    font-family: 'inter-bold', sans-serif;
    font-size: 1.3em;
  }

  .above-cont-text span {
    color: var(--blue-brand);
  }

  .double-arrow-down {
    width: 2.5em;
    height: 2.5em;
    background-image: url('/assets/images/blue-arrow-double.svg');
    background-size: 100% 100%;
  }

  /* Styles for Hero Button Section */
  .hero-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-bottom: 60px;
  }

  .hero-button a {
    padding: 0.6em 2em;
    font-size: 1.6em;
    font-family: 'inter-bold', sans-serif;
    background-color: var(--blue-brand);
    color: white;
    border: 3px solid var(--blue-brand);
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }

  .hero-button a:hover {
    background-color: white;
    color: var(--blue-brand);
    transform: scale(0.9);
  }

  @media (max-width: 490px) {
    ${tagName} .section-header {
      line-height: 1.2;
    }

    .hero-button {
      padding: 10px; 
      padding-bottom: 60px;
    }
  
    .hero-button a {
      padding: 0.6em 1.7em;
      font-size: 1.4em;
      border-radius: 30px;
    }
  }
`;

/** Construct the Typeform URL*/
function getTypeformURL() {
  const baseUrl = 'https://tibiaspe9ro.pro.typeform.com/website-leads';
  let utmString = UTM.load('?'); 
  return baseUrl + utmString;
}

/** Renders the CTA sections (Above-Cont + button) */
function renderCTASections() {
  const finalCTA = getTypeformURL();
  return /*html*/`
    <div class="above-cont">
      <div class="double-arrow-down"></div>
      <div class="above-cont-text">
        Mehr Informationen<br><span>zum Angebot</span>
      </div>
      <div class="double-arrow-down"></div>
    </div>
    <div class="hero-button">
      <a 
        class="button" 
        href="${finalCTA}"
        target="_blank"
      >
        <span>Jetzt anmelden!</span>
      </a>
    </div>
  `;
}

/**
 * Renders the entire blog (title, date, summary, thumbnail, sections, CTA).
 */
function renderBlogHTML(blogData) {
  const safeTitle = blogData.title?.trim() || "Untitled Post";
  const safeDate = blogData.date_published
      ? new Date(blogData.date_published).toLocaleDateString()
      : "No publish date";
  const safeReadTime = blogData.read_time && blogData.read_time > 0
      ? `${blogData.read_time} min Lesedauer`
      : "N/A";
  const safeSummary = blogData.summary?.trim() || "No summary available";
  const safeThumbnail = blogData.thumbnail?.trim()
      ? blogData.thumbnail
      : "https://via.placeholder.com/600x400";

  return /*html*/`
    <h1 class="blog-title">${safeTitle}</h1>
    <div class="blog-meta">
      Veröffentlicht am ${safeDate} | ${safeReadTime}
    </div>
    <h2 class="blog-summary">${safeSummary}</h2>
    <img src="${safeThumbnail}" alt="${safeTitle}" class="thumbnail" />

    <!-- Render the "sections" array if it exists -->
    ${renderSections(blogData.sections || [])}

    <!-- CTA sections -->
    ${renderCTASections()}

    <style>${css}</style>
  `;
}

/**
 * Convert each item in the `sections` array (header, paragraphs, list, etc.) into HTML.
 */
function renderSections(sections = []) {
  return sections
    .map((section) => {
      // Header
      const header = section.header ? section.header.trim() : "No Header";
      
      // Process paragraphs: if it's an array, join its items; if it's a string, use it as is.
      let paragraphsContent = "";
      if (Array.isArray(section.paragraphs)) {
        paragraphsContent = section.paragraphs.join("\n");
      } else if (typeof section.paragraphs === "string") {
        paragraphsContent = section.paragraphs;
      }
      const paragraphsHTML = paragraphsContent
        .split("\n")
        .filter(line => line.trim() !== "")
        .map((p) => `<p>${p.trim()}</p>`)
        .join("");

      // Process additional paragraph(s): try both keys.
      let additionalParagraphContent = "";
      if (section.additional_paragraph) {
        additionalParagraphContent = section.additional_paragraph;
      } else if (section.additional_paragraphs) {
        additionalParagraphContent = Array.isArray(section.additional_paragraphs)
          ? section.additional_paragraphs.join("\n")
          : section.additional_paragraphs;
      }
      const additionalParagraphHTML = additionalParagraphContent
        ? `<p>${additionalParagraphContent.trim()}</p>`
        : "";

      // Section image
      const sectionImage = section.image ? section.image.trim() : "";
      const imageHTML = sectionImage
        ? `<img src="${sectionImage}" class="section-image" alt="Section image" />`
        : "";

      // List items
      const listItems = Array.isArray(section.list) ? section.list : [];
      const listHTML = listItems.length
        ? `<ul>${listItems.map((item) => `<li>${item}</li>`).join('')}</ul>`
        : "";

      return /*html*/ `
        <div class="section">
          <div class="section-header">${header}</div>
          <div class="section-summary">
            ${paragraphsHTML}
            ${listHTML}
            ${additionalParagraphHTML}
          </div>
          ${imageHTML}
        </div>
      `;
    })
    .join("");
}

class BlogIndividualPage extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    // 1) Show a skeleton (loading) state while we fetch data
    this.innerHTML = `
      <div class="skeleton-title"></div>
      <div class="skeleton-title-bar"></div>
      <div class="skeleton-meta"></div>
      ${Array(2).fill(0).map(() => `<div class="skeleton-summary"></div>`).join('')}
      <div class="skeleton-thumbnail"></div>
      ${Array(6).fill(0).map(() => `<div class="skeleton-paragraph"></div>`).join('')}
      <style>${css}</style>
    `;

    // 2) Extract the slug from the URL path
    const pathParts = window.location.pathname.split("/");
    const rawSlug = pathParts[2] || "";
    const slugFromURL = decodeURIComponent(rawSlug);

    // 3) Get the list of all blogs (just to map slug -> blog ID)
    const allBlogs = await getAllBlogPosts();
    if (!Array.isArray(allBlogs)) {
      this.innerHTML = `<p>Could not load blog data.</p>`;
      return;
    }

    // 4) Find the matching blog by comparing slugified title
    const matchedBlog = allBlogs.find((b) => slugify(b.title) === slugFromURL);
    if (!matchedBlog) {
      this.innerHTML = `<h1>404 - Blog Not Found</h1>`;
      return;
    }

    // 5) Fetch the *full* blog details from the single-blog API (to get sections, etc.)
    const blogData = await getBlogPostById(matchedBlog.id);
    if (!blogData) {
      this.innerHTML = `<p>Could not fetch the full blog details.</p>`;
      return;
    }

    if (blogData && blogData.category) {
      const bannerElement = document.createElement('blog-banner');
      bannerElement.setAttribute('category', blogData.category);
      
      this.prepend(bannerElement);
    }
    
    // Dynamically set pageTitle and pageDescription
    const dynamicTitle = `${blogData.title} | Franklin Institute of Applied Sciences`;
    const dynamicDescription = blogData.summary?.trim();

    util.setPageTitle(dynamicTitle);
    util.setPageDescription(dynamicDescription);

    this.storeUTMData(blogData);

    var updateMainMenuEvent = new CustomEvent("update-main-menu");
    var mainMenu = document.querySelector('main-menu');
    mainMenu.dispatchEvent(updateMainMenuEvent);

    // 6) Render the final blog (title, summary, sections, images, CTA, etc.)
    this.innerHTML = renderBlogHTML(blogData);

    // 7) Append the carousel at the bottom
    if (blogData.id) {
      const carouselElement = document.createElement('blog-carousel-component');
      carouselElement.setAttribute('exclude-id', blogData.id);
      this.appendChild(carouselElement);
    }
  }

  storeUTMData(blogData) {
    // Store the blog ID + slug in session so UTM can pass it forward
    var utmBlogData = {
      blogData: {
        id: blogData.id,
        slug: slugify(blogData.title)
      }
    };
    UTM.store(utmBlogData);

    var resetAppConfigEvent = new CustomEvent("reset-app-config");
    window.dispatchEvent(resetAppConfigEvent);
  }
}

customElements.define(tagName, BlogIndividualPage);

export default `<${tagName}></${tagName}>`;
