const tagName = "details-zum-bildungstraeger-section";

var css = /*css*/`
  ${tagName} {
    display: block;
    font-family: 'inter-medium';
  }

  .hero-3 {
    font-size: 44px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-family: 'inter-bold';
    margin-top: 20px;
  }

  /* Outer wrapper for the two-column or single-column layout */
  ${tagName} .grid-outer-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }

  ${tagName} .section-grid {
    max-width: 1100px;
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  /* The main white box wrapper */
  ${tagName} .section-grid-box {
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  ${tagName} .card-title-area {
    background: var(--blue-brand, #00c8f0);
    color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    min-height: 60px; 
  }

  /* Actual text in the blue bar */
  ${tagName} .section-title {
    margin: 0;
    font-family: 'inter-bold';
    font-size: 16px;
    line-height: 1.2rem; 
  }

  /* The arrow icon: hidden on screens >= 500px, visible < 500px. */
  ${tagName} .blue-title-arrow {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background: url('/assets/images/arrow.png') no-repeat center; 
    background-size: contain;
    transition: transform 0.2s ease;
    margin-left: 10px; 
  }

  @media(min-width: 500px) {
    ${tagName} .blue-title-arrow {
      display: none !important;
    }
  }

  ${tagName} .box-inner {
    padding: 20px 25px;
  }

  ${tagName} .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }
  ${tagName} .form-group label {
    margin-bottom: 0.3em;
    font-weight: bold;
    font-family: 'inter-bold';

  }
  ${tagName} .fake-input {
    background-color: #ffffff;
    font-size: 1rem;
  }

  /* On narrower screens (< 900px), switch to single column */
  @media(max-width: 899px){
    ${tagName} .section-grid {
      grid-template-columns: 1fr;
    }
  }

  /* On very small screens (< 500px), hide content by default (accordion) */
  @media(max-width: 499px){
    .hero-3 {
      font-size: 28px;
      margin-bottom: 20px;
      padding-bottom: 10px;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    ${tagName} .grid-outer-cont {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    ${tagName} .section-grid-box:not(.open) .box-inner {
      display: none;
    }
    ${tagName} .section-grid-box.open .blue-title-arrow {
      transform: rotate(180deg);
    }

    /* Slightly smaller title on tiny screens */
    ${tagName} .section-title {
      font-size: 16px;
      line-height: 1.2rem;
    }
  }

    .description {
        font-family: 'inter';
        font-size: 16px;
        color: #333;
        line-height: 1.5;
        text-align: center;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        padding-bottom: 15px;
        padding-right:20px;
        padding-left: 20px; 
    }

`;

const renderInnerHTML = (options) => {
  return /*html*/`
    <div class="hero-3 centered">Deine Daten für deinen Antrag</div>
    <div class="description">
    Hier findest du eine Übersicht der relevanten Daten, die du benötigst, um deine Antrag auf einen Bildungsgutschein zu stellen.
        </div>
    <div class="grid-outer-cont">
      <div class="section-grid">

        <!-- LEFT BOX -->
        <div class="section-grid-box">
          <div class="card-title-area">
            <div class="section-title">IT-Vertriebsmanager und CRM Spezialist</div>
            <div class="blue-title-arrow"></div>
          </div>
          <div class="box-inner">
            <div class="form-group">
              <label>Maßnahmenummer</label>
              <div class="fake-input">843/1062/2025</div>
            </div>
            <div class="form-group">
              <label>Name des Bildungsträgers</label>
              <div class="fake-input">Franklin Institute of Applied Sciences Pioneer People GmbH</div>
            </div>
            <div class="form-group">
              <label>Land</label>
              <div class="fake-input">Deutschland</div>
            </div>
            <div class="form-group">
              <label>Postleitzahl</label>
              <div class="fake-input">80636</div>
            </div>
            <div class="form-group">
              <label>Ort</label>
              <div class="fake-input">München</div>
            </div>
            <div class="form-group">
              <label>Straße</label>
              <div class="fake-input">Arnulfstraße</div>
            </div>
            <div class="form-group">
              <label>Hausnummer</label>
              <div class="fake-input">61</div>
            </div>
          </div>
        </div>

        <!-- RIGHT BOX -->
        <div class="section-grid-box">
          <div class="card-title-area">
            <div class="section-title">IT- & Software-Berater für digitales Produktmanagement</div>
            <div class="blue-title-arrow"></div>
          </div>
          <div class="box-inner">
            <div class="form-group">
              <label>Maßnahmenummer</label>
              <div class="fake-input">843/1071/2025</div>
            </div>
            <div class="form-group">
              <label>Name des Bildungsträgers</label>
              <div class="fake-input">Franklin Institute of Applied Sciences Pioneer People GmbH</div>
            </div>
            <div class="form-group">
              <label>Land</label>
              <div class="fake-input">Deutschland</div>
            </div>
            <div class="form-group">
              <label>Postleitzahl</label>
              <div class="fake-input">80636</div>
            </div>
            <div class="form-group">
              <label>Ort</label>
              <div class="fake-input">München</div>
            </div>
            <div class="form-group">
              <label>Straße</label>
              <div class="fake-input">Arnulfstraße</div>
            </div>
            <div class="form-group">
              <label>Hausnummer</label>
              <div class="fake-input">61</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <style>${css}</style>
  `;
};

class CustomElem extends HTMLElement {
  connectedCallback(){
    var d = window.decodeURIComponent;
    this.options = JSON.parse(d(this.getAttribute('element-options') || '{}'));
    this.innerHTML = renderInnerHTML(this.options);

    // Mobile accordion toggle
    const titleBars = this.querySelectorAll('.card-title-area');
    titleBars.forEach((bar) => {
      bar.addEventListener('click', () => {
        const parentBox = bar.closest('.section-grid-box');
        parentBox.classList.toggle('open');
      });
    });
  }
}

customElements.define(tagName, CustomElem);

function render(options){
  var e = window.encodeURIComponent;
  return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render;

