const tagName = "fcc-6-schritte-component";

// CSS to be applied globally
var fccComponentCss = /*css*/ `
.fcc-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px; /* Reduced padding for compactness */
    background-color: transparent;
}

.fcc-svg-container {
    width: 100%;
    max-width: 800px; /* Increased max width for SVG container */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

svg {
    width: 100%;
    height: auto;
    max-width: 100%; /* Ensures SVG scales within its container */
    overflow: visible;
}

/* Hover effects for desktop SVG elements */
#desktop-01,
#desktop-02,
#desktop-03,
#desktop-04,
#desktop-05,
#desktop-06 {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.15));
    transition: transform 0.3s ease;
    transform-origin: center;
}

#desktop-01 {
    position: relative;
    transition: transform 0.3s ease;
}

#desktop-01:hover {
    position: absolute;
    transform: translateX(5px) !important;
    top: 0 !important;
}

#desktop-02:hover {
    transform: translateX(5px);
}

#desktop-03:hover {
    transform: translateY(5px) !important;
}

#desktop-04:hover,
#desktop-06:hover,
#desktop-05:hover {
    transform: translateX(-5px);
}

/* Title and text styles */
.centered-text {
    text-align: center;
    margin-bottom: 0.1em;
    font-family: 'inter-bold';
    font-size: clamp(28px, 6.18vw, 45px);
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
}

.light-blue-text {
    color: var(--blue-brand);
}

/* Mobile adjustments */
@media (max-width: 630px) {
    .fcc-svg-container {
        display: none;
    }

    .centered-text {
        padding-top: 0px;
    }

    .fcc-container {
        display: none;
    }

    .fcc-svg-container-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 350px; /* Slightly larger max width for mobile view */
        margin: 0 auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

/* Hide mobile SVG on larger screens */
@media (min-width: 631px) {
    .fcc-svg-container-mobile {
        display: none;
    }
}
`;

// HTML template for the component
var fccComponentTemplate = /*html*/ `
<div class="section-message centered-text">
    <span style="font-size: clamp(28px, 6.18vw, 45px); font-family: 'inter-bold';">
        Franklin Career Center <br>
    </span>
    <span class="section-message centered-text" style="font-size: clamp(28px, 6.18vw, 45px); font-family: 'inter-bold';">
        Unsere
        <span class="light-blue-text" style="font-size: clamp(28px, 6.18vw, 45px); font-family: 'inter-bold';">
            6 Schritte zum Traumjob
        </span>
    </span>
</div>

<!-- Desktop SVG container -->
<section class="fcc-container">
    <div class="fcc-svg-container" id="desktop-svg-container"></div>
</section>

<!-- Mobile SVG container -->
<section class="fcc-container-mobile">
    <div class="fcc-svg-container-mobile" id="mobile-svg-container"></div>
</section>
`;

// Function to load the external SVG files
function loadSVG(svgUrl, containerElement) {
    fetch(svgUrl)
        .then(response => response.text())
        .then(svgData => {
            containerElement.innerHTML = svgData;
            const svgElement = containerElement.querySelector('svg');
            if (svgElement) {
                svgElement.style.width = "100%";
                svgElement.style.height = "auto";
                svgElement.style.maxWidth = "800px"; // Ensures SVG stays within a larger range on desktop
            }
        })
        .catch(error => console.error('Error loading SVG:', error));
}

// Define the custom element without using Shadow DOM
class FCC6SchritteComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        // Append the template content
        this.innerHTML = fccComponentTemplate;

        // Append the CSS styles directly to the document if not already added
        if (!document.getElementById('fcc-6-schritte-styles')) {
            const style = document.createElement("style");
            style.id = 'fcc-6-schritte-styles';
            style.textContent = fccComponentCss;
            document.head.appendChild(style);
        }

        // Load the desktop and mobile SVGs
        const desktopSvgContainer = this.querySelector('#desktop-svg-container');
        const mobileSvgContainer = this.querySelector('#mobile-svg-container');

        loadSVG('/assets/images/FCC 6 Schritte.svg', desktopSvgContainer);
        loadSVG('/assets/images/FCC 6 Schritte Mobile.svg', mobileSvgContainer);
    }
}

customElements.define(tagName, FCC6SchritteComponent);

export default FCC6SchritteComponent;
